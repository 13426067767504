<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">Create License Order</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle">Order Licenses for a company</a>
</mat-toolbar-row>
<mat-divider></mat-divider>

<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="orderLicenseForm" (ngSubmit)="OnSubmit()" (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="formSubmitting">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Order Name</mat-label>
                <input matInput formControlName="orderName" maxlength="40" />
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="orderDescription"></textarea>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Reference Number</mat-label>
                <input matInput formControlName="referenceNumber" maxlength="40" required />
            </mat-form-field>
        </section>
        <section>
            <mat-form-field (click)="selectCompany($event)" appearance="outline">
                <mat-label>Company</mat-label>
                <input matInput formControlName="companyField" [value]="selectedCompany.name" readonly required />
                <mat-error>Company has no teams!</mat-error>
            </mat-form-field>
            <button mat-icon-button class="align-form-field" (click)="selectCompany($event)" type="button"
                color="primary" disableRipple>
                <mat-icon>search</mat-icon>
            </button>
        </section>
        <section>
            <mat-form-field style="margin-left: 1em;" appearance="outline">
                <mat-label>Team</mat-label>
                <mat-select required formControlName="teamName">
                    <mat-option *ngFor="let team of teams" [value]="team.id">
                        {{team.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Number of Licenses</mat-label>
                <input matInput type="number" formControlName="quantity" min="1" max="500" required />
                <mat-hint>Max is 500</mat-hint>
                <mat-error>Invalid quantity!</mat-error>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Expiration Date</mat-label>
                <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="expirationDate" required>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year"></mat-datepicker>
            </mat-form-field>
        </section>
        <section>
            <app-calendar-short-cuts (selectedDate)="selectDate($event)"></app-calendar-short-cuts>
        </section>
        <mat-card class="view-information-card form-card">
            <div class="view-information-toolbar">
                <a class="view-information-table-title">Modules</a>
            </div>
            <div class="flex">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Module Preset</mat-label>
                    <mat-select (selectionChange)="setPreset($event)" formControlName="modulePreset">
                        <mat-option>--</mat-option>
                        <mat-option value="Custom">Custom</mat-option>
                        <mat-option *ngFor="let preset of modulePresets" [value]="preset.id">
                            {{preset.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <section style="width: 100% !important; flex-direction: column !important;">
                <section style="width: 100% !important;">
                    <mat-label class="bolded form-text">Modules</mat-label>
                    <span class="spacer"></span>
                    <a class="form-text">{{selectedModules.length}} selected</a>
                </section>

                <app-uid-spinner [visible]="!appsLoaded"></app-uid-spinner>
                <div *ngIf="appsLoaded">
                    <mat-expansion-panel color="primary" *ngFor="let app of applications" [expanded]="step == app.guid"
                        (opened)="setStep(app.guid)">
                        <mat-expansion-panel-header>
                            <mat-panel-title> {{app.description}}</mat-panel-title>
                            <mat-panel-description style="justify-content: end; flex: initial;">
                                {{applicationModuleCount[app.guid]}}/{{app.modules.length}} selected
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-checkbox *ngFor="let module of app.modules" color="primary" [value]="module.guid"
                            (change)="selectModule($event)" [checked]="isSelected(module.guid)" style="width: 100%;"
                            [disabled]="formSubmitting">
                            <a class="bolded">{{module.name}}</a>{{module.description != null && module.description !=
                            "" ? ": "
                            : ""}}{{module.description}}
                        </mat-checkbox>
                    </mat-expansion-panel>
                </div>
            </section>
        </mat-card>
        <section>
            <button mat-raised-button class="submit-button" color="primary" type="submit">Order</button>
        </section>
    </fieldset>
</form>