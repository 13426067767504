import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '../common/Helpers/login/login.service';
import { User } from '../users/interfaces/user.interface';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {

  /** Ouputs event when the menu button is hit */
  @Output() toggle = new EventEmitter<boolean>();

  version: string;
  public loggedIn = false;
  public user: User = {
    id: 0,
    username: '',
    displayName: '',
    emailAddress: ''
  }

  public open: boolean = false;

  constructor(private service: LoginService) {
  }

  ngOnInit() {
    this.service.observeAuth.subscribe(value => {
      this.loggedIn = value == false ? this.service.tokenExists : value;
    });
    this.service.observeUser.subscribe(value => this.user = value)
    this.version = environment.appVersion;
  }

  ngOnDestroy(): void {
    this.service.observeAuth.unsubscribe();
    this.service.observeUser.unsubscribe();
  }

  /**
   * toggles side nav visibility
   */
  toggleDrawer() {
    this.open = !this.open;
    this.toggle.emit(this.open);
  }

  /**
   * logs user out
   */
  logout() {
    this.service.logout();
  }
}
