import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TimeHelpers } from '../../Helpers/time-helper';
import { DateInformation } from './interfaces/date-information';

@Component({
  selector: 'app-calendar-short-cuts',
  templateUrl: './calendar-short-cuts.component.html',
  styleUrls: ['./calendar-short-cuts.component.css']
})
export class CalendarShortCutsComponent implements OnInit {
/** Selected DateTime from list */
  @Output() selectedDate = new EventEmitter<Date>();
  
  dates: DateInformation[] = [];
  constructor() {
    var now = Date.now()
    this.dates = [
      {name: "30d", date: TimeHelpers.addDays(now, 30)},
      {name: "90d", date: TimeHelpers.addDays(now, 90)},
      {name: "1y", date: TimeHelpers.addDays(now, 365)},
      {name: "DNE", date: new Date("12/31/2999")},
    ]
  }

  ngOnInit(): void {
  }

  setDate(selected: Date) {
    this.selectedDate.emit(selected)
  }
}
