import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormHelpers } from '../common/Helpers/form-helper';
import { LoginService } from '../common/Helpers/login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
/**
 * Component used to log in user
 */
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  formSubmitting: boolean = false;

  constructor(private loginService: LoginService, private router: Router) {
    loginService.verifyToken()
  }

  ngOnInit() {
    if (this.loginService.tokenExists) {
      this.router.navigate(['/'])
    }
    this.loginForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl('')
    });
  }

  /**
   * Attempts user login and reroutes based on result
   */
  OnSubmit() {
    this.formSubmitting = true;
    this.loginService.login(this.loginForm.value.username, this.loginForm.value.password).add(() => {
      if (this.loginService.tokenExists) {
        this.router.navigate(['/']);
      } else {
        this.formSubmitting = false;
        FormHelpers.resetForm(this.loginForm);
      }
    });
  }

}
