import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ApiPaths } from '../enums/api-paths';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorObject } from '../common/Interfaces/paginator-object';
import { PageEvent } from '@angular/material/paginator';
import { User } from './interfaces/user.interface';
import { PaginatedApiResponse } from '../common/Interfaces/paginated-api-repsonse.interface';
import { ApiHelper } from '../common/Helpers/api-helper';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

/**
 * Component used to display a list of users 
 */
@Injectable()
export class UsersComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  public loaded = false;
  public users: User[];
  public usersDataSource: MatTableDataSource<User> = new MatTableDataSource();
  public columns: string[] = ['displayName', 'info'];

  public paginatorObject: PaginatorObject = new PaginatorObject();

  constructor(http: HttpClient) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.User);
  }

  ngOnInit() {
    this.loadUsers();
  }

  /**
   * Update users list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadUsers();
  }

  /**
   * Retrieve current page of users from database
   * @param page page number to get
   */
  loadUsers() {
    let url = `${this.apiUrl}?${this.paginatorObject.pageQuery}`
    this.loaded = false;

    this.httpClient.get<PaginatedApiResponse<User[]>>(url).subscribe(result => {
      this.users = result.body;
      this.usersDataSource.data = result.body;
      this.paginatorObject.onPaginatedResponse(result);
      this.loaded = true;
    },
      error => console.error(error));
  }
}
