/**
 * Various api versions
 */
export enum ApiVersions{
    V1 = "v1",
    V2 = "v2",

    /**
     * Default Api Version
     */
    DEFAULT_VERSION = "v1"
}