<a mat-dialog-title>Add Licenses</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<mat-dialog-content>
    <form [formGroup]="addLicensesForm" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="formSubmitting">
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Number of Licenses</mat-label>
                    <input matInput type="number" formControlName="quantity" min="1" max="500" required />
                    <mat-hint>Max is 500</mat-hint>
                    <mat-error>Invalid quantity!</mat-error>
                </mat-form-field>
            </section>
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Expiration Date</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="data.currentExpirationDate"
                        formControlName="expirationDate" required>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            </section>
        </fieldset>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" (click)="addLicenses()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>