<mat-toolbar class="section-header">
    <a class="section-header-title">Licenses</a>
    <button mat-raised-button class="add-button section-header-button" [routerLink]="['./order']" disableRipple>New Order</button>
    <button mat-raised-button color="primary" class="section-header-button" [routerLink]="['./offlineactivation']" disableRipple>License Offline
        Activation</button>
</mat-toolbar>

<div *ngIf="loaded">
    <mat-table [dataSource]="licensesDataSource">
        <ng-container matColumnDef="key">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.guid}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" [routerLink]="['./',row.guid]" disableRipple>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;">
        </mat-row>
    </mat-table>

    <mat-paginator [pageIndex]="paginatorObject.pageIndex" [pageSize]="paginatorObject.pageSize"
        [length]="paginatorObject.totalRecords" (page)="pageChanged($event)" aria-label="Select page" hidePageSize>
    </mat-paginator>
</div>