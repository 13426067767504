import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedApiResponse } from '../common/Interfaces/paginated-api-repsonse.interface';
import { ApiPaths } from '../enums/api-paths';
import { License } from './interfaces/license.interface';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { PaginatorObject } from '../common/Interfaces/paginator-object';
import { ApiVersions } from '../enums/api-versions';
import { ApiHelper } from '../common/Helpers/api-helper';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.css']
})

/** Component used to display a paginated list of licenses */
@Injectable()
export class LicensesComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  public loaded: boolean;
  public licenses: License[];
  public licensesDataSource: MatTableDataSource<License> = new MatTableDataSource();
  public columns: string[] = ['key', 'info'];

  public paginatorObject: PaginatorObject = new PaginatorObject();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(http: HttpClient) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.License)
  }

  ngOnInit() {
    this.loadLicenses();
  }

  /**
   * Update licenses list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadLicenses();
  }

  /** Loads new license list from database */
  loadLicenses() {
    let url = `${this.apiUrl}?${this.paginatorObject.pageQuery}`;
    this.loaded = false;

    this.httpClient.get<PaginatedApiResponse<License[]>>(url).subscribe(result => {
      this.licenses = result.body;
      this.paginatorObject.onPaginatedResponse(result);
      this.licensesDataSource.data = result.body;
      this.loaded = true;
    },
      error => console.error(error));
  }

}
