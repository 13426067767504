import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { LoginRequest } from 'src/app/login/interfaces/loginRequest.interface';
import { LoginResponse } from 'src/app/login/interfaces/loginResponse.interface';
import { User } from 'src/app/users/interfaces/user.interface';
import { environment } from 'src/environments/environment';
import { ApiHelper } from '../api-helper';
import { SnackBarHelper } from '../snackbar-helper';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {

  private authUrl: string;
  private userUrl: string;
  private httpClient: HttpClient;

  private authenticated: boolean = false;
  private authSubject: Subject<boolean>;
  private authUser: Subject<User>;
  private user: User = {
    id: 0,
    username: 'placeholder',
    displayName: '',
    emailAddress: ''
  }

  constructor(private router: Router, http: HttpClient, private snackBar: MatSnackBar) {
    this.authUrl = `${ApiHelper.GetUrl(ApiPaths.Login)}/authenticate`;
    this.userUrl = ApiHelper.GetUrl(ApiPaths.User);
    this.httpClient = http;

    this.authSubject = new Subject<boolean>();
    this.authUser = new Subject<User>();
  }

  ngOnDestroy(): void {
    this.authSubject.complete();
    this.authUser.complete();
  }
  /**
   * logs user in
   * @param username username
   * @param password password
   */
  public login(username: string, password: string) {
    const login: LoginRequest = {
      username: username,
      password: password
    }

    return this.httpClient.post<ApiResponse<LoginResponse>>(this.authUrl, login).subscribe(result => {
      if (result.body != null) {
        document.cookie = `token=${result.body.token}; expires=${new Date(result.body.expiresOn).toUTCString()}; path=/; SameSite=Lax; ${environment.requireHttps ? "secure" : ""}`;
        this.router.navigate(['./'])
      }
      else {
        SnackBarHelper.sendSnackbar(this.snackBar, "Login Failed", "failure-snackbar")
      }
    }, error => {
      SnackBarHelper.sendSnackbar(this.snackBar, "Login Failed", "failure-snackbar")
    })
  }
  /** logs user out and removes cookie */
  public logout() {
    document.cookie = "token=; max-age=0; path=/;"
    this.updateAuth(null)
    this.router.navigate(['/login']);
  }
  /** returns observable subject of user authenticated status */
  public get observeAuth(): Subject<boolean> {
    this.authSubject.next(this.authenticated);
    return this.authSubject;
  }
  /** returns observable subject of current user */
  public get observeUser(): Subject<User> {
    return this.authUser;
  }
  /**
   * Verifies current token is valid and updates user information
   */
  public verifyToken() {
    if (this.tokenExists) {
      this.httpClient.get<ApiResponse<User>>(`${this.userUrl}/token?login_token=${this.getToken}&depth=2`).subscribe({
        next: (result) => {
          this.updateAuth(result.body);
        }
      })
    }
    else {
      this.logout();
    }
  }
  /**
   * Updates user and auth status
   * @param user new user
   */
  private updateAuth(user: User) {
      this.authenticated = user != null;
      this.authSubject.next(this.authenticated);
      this.user = user;
  }

  /** gets authentication token */
  public get getToken() {
    return document.cookie.split(';').find((row) => row.trim().startsWith("token="))?.split('=')[1];
  }

  /** Returns if token exists */
  public get tokenExists() {
    let token = this.getToken;
    return token != null && token != "undefined"
  }

  /** gets current user username */
  public get getUsername() {
    return this.user.username;
  }
}