import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Details } from '../interfaces/details';
import { MatTableDataSource } from '@angular/material/table';
import { DialogRef } from '@angular/cdk/dialog';
import { Summary } from '../interfaces/summary';
import { Router } from '@angular/router';

@Component({
  selector: 'app-active-version-details',
  templateUrl: './active-version-details.component.html',
  styleUrls: ['./active-version-details.component.css']
})
export class ActiveVersionDetailsComponent implements OnInit {

  public dataSource: MatTableDataSource<Details> = new MatTableDataSource();
  public columns: string[] = ['customer', 'license', 'last-check-in', 'info'];
  public versionName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Summary, public dialogRef: DialogRef, public router: Router) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.data.activeVersion.details;
    this.versionName = this.data.productInfo.name;
  }

  /**
   * Navigates to selected license
   * @param row row with license to load
   */
  loadLicense(row: Details){
    this.dialogRef.close();
    this.router.navigate([`licenses/${row.license}`]);
  }
}
