import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { LicenseHelper } from 'src/app/common/Helpers/license-helper';
import { TimeHelpers } from 'src/app/common/Helpers/time-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { PaginatedApiResponse } from 'src/app/common/Interfaces/paginated-api-repsonse.interface';
import { PaginatorObject } from 'src/app/common/Interfaces/paginator-object';
import { ApiPaths } from 'src/app/enums/api-paths';
import { ApiVersions } from 'src/app/enums/api-versions';
import { LicenseOrder } from 'src/app/license-orders/interfaces/license-order.interface';
import { Module } from 'src/app/modules/interfaces/module.interface';
import { UpdateTrack } from 'src/app/tracks/interfaces/update-track';
import { SetUpdateTrackDialogComponent } from 'src/app/tracks/set-update-track-dialog/set-update-track-dialog.component';
import { environment } from 'src/environments/environment';
import { License } from '../interfaces/license.interface';

@Component({
  selector: 'app-view-license',
  templateUrl: './view-license.component.html',
  styleUrls: ['./view-license.component.css']
})
export class ViewLicenseComponent implements OnInit {
  //#region properties
  private httpClient: HttpClient;
  private licenseUrl: string;
  private licenseOrderUrl: string
  private moduleUrl: string;
  private updateTrackUrl: string;

  public license: License = {
    guid: '',
    referenceNumber: '',
    activatedOn: '',
    activated: false,
    mode: '',
    lastStatusUpdate: '',
    modulePreset: undefined,
    modules: [],
    expirationDate: '',
    licenseOrderId: 0,
    enabled: false,
    status: '',
    publicKey: '',
    historySetId: 0
  }

  public licenseOrder: LicenseOrder = {
    id: 0,
    referenceNumber: '',
    name: '',
    description: '',
    modulePreset: undefined,
    expirationDate: '',
    team: undefined,
    historySetId: 0,
    licenses: []
  }

  public reloadHistoryFlag: boolean = false;
  public modulesLoaded = false;
  public modules: Module[];
  public modulesDataSource: MatTableDataSource<Module> = new MatTableDataSource();
  public modulePaginatorObject: PaginatorObject = new PaginatorObject();
  public moduleColumns: string[] = ['application', 'name'];
  public daysUntilExpiration: number;
  public status: string;
  public color: string;

  public updateTracks: UpdateTrack[];
  public currentUpdateTrack: UpdateTrack = {
    id: 0,
    name: ''
  };
  //#endregion

  //#region constructor
  constructor(http: HttpClient, private route: ActivatedRoute, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public dialog: MatDialog) {
    this.httpClient = http;
    this.licenseUrl = ApiHelper.GetUrl(ApiPaths.License);
    this.licenseOrderUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
    this.moduleUrl = ApiHelper.GetUrl(ApiPaths.Module);
    this.updateTrackUrl = ApiHelper.GetUrl(ApiPaths.UpdateTrack);

    this.matIconRegistry.addSvgIcon(
      `uid_edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/edit.svg")
    );

    this.modulePaginatorObject.pageSize = environment.shortPage;
  }
  //#endregion

  //#region OnInit
  ngOnInit(): void {
    this.loadLicense().add(() => {
      this.loadModules();
      if (this.license.licenseOrderId) {
        this.loadLicenseOrder();
        this.loadCurrentUpdateTrack();
      }
    });
  }
  //#endregion

  //#region formatters
  /**
   * formats expiration date
   * @param date expiration date
   * @returns formatted expiration date
   */
  ExpirationDateString(date: string) {
    return TimeHelpers.formatDate(date)
  }
  /**
   * Formats activated on string
   * @param date activation date
   * @returns formatted date
   */
  ActivationDateString(date: string) {
    return TimeHelpers.formatDate(date)
  }
  /**
   * Formats last check in
   * @param date activation date
   * @returns formatted date
   */
  LastCheckInString(date: string) {
    return TimeHelpers.formatWithTime(date)
  }
  /**
   * Formats raw json status to be readable
   * @returns formatted status
   */
  StatusString(){
    return JSON.stringify(JSON.parse(this.license.status), null, 2);
  }
  //#endregion

  //#region events
  /**
   * Update modules list based on new page index
   * @param event event sent on page change
   */
  modulePageChanged(event: PageEvent) {
    this.modulePaginatorObject.onPageEvent(event);
    this.loadModules();
  }
  //#endregion

  //#region helpers
  /**
   * Loads license from database
   * @returns observable licenses
   */
  loadLicense() {
    return this.httpClient.get<ApiResponse<License>>(`${this.licenseUrl}/${this.route.snapshot.params.id}?depth=1`).subscribe({
      next: (result) => {
        this.license = result.body;
        if (result.body.expirationDate != null)
          this.daysUntilExpiration = TimeHelpers.daysUntil(new Date(Date.now()), new Date(result.body.expirationDate));
        [this.status, this.color] = LicenseHelper.getStatusString(this.license, this.daysUntilExpiration < 0);
      },
      error: (e) => console.log(e)
    })
  }

  /**
   * Load license order from database
   */
  loadLicenseOrder() {
    this.httpClient.get<ApiResponse<LicenseOrder>>(`${this.licenseOrderUrl}/${this.license.licenseOrderId}`).subscribe({
      next: (result) => {
        this.licenseOrder = result.body;
      },
      error: (e) => console.log(e)
    })
  }

  /**
   * Loads new module list from database 
   * @param page the page the user has requested
   */
  loadModules() {
    // TODO: Update order to use LicenseGuid instead, LIC-171
    let paged_url = `${this.moduleUrl}?${this.modulePaginatorObject.pageQuery}&license_order_id=${this.license.licenseOrderId}&depth=0`;
    this.modulesLoaded = false;

    this.httpClient.get<PaginatedApiResponse<Module[]>>(paged_url).subscribe(result => {
      this.modules = result.body;
      this.modulePaginatorObject.onPaginatedResponse(result);
      this.modulesDataSource.data = result.body;
    },
      error => console.error(error));
    this.modulesLoaded = true;
  }

  /**
   * Loads current update track from database
   */
  loadCurrentUpdateTrack() {
    this.httpClient.get<ApiResponse<UpdateTrack>>(`${this.updateTrackUrl}/${this.license.guid}`).subscribe({
      next: (result) => {
        this.currentUpdateTrack = result.body;
      },
      error: (e) => console.log(e)
    })
  }
  
  /**
  * Launches set update track dialog
  * @param event button event
  */
  setUpdateTrack(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(SetUpdateTrackDialogComponent, {
      data: {
        key: this.license.guid,
        currentUpdateTrack: this.currentUpdateTrack
      },
      autoFocus: false,
      panelClass: "view-panel"
    })
    dialogRef.afterClosed().subscribe().add(() => {
        this.loadCurrentUpdateTrack();
        this.reloadHistoryItems();
    })
  }
  //#endregion
  /**
   * Flips a boolean flag which causes the child component OnChanges to fire
   */
  reloadHistoryItems() {
    this.reloadHistoryFlag = !this.reloadHistoryFlag;
  }
}
