import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { SetUpdateTrack } from 'src/app/licenses/interfaces/set-update-track';
import { SetUpdateTrackRequest } from '../interfaces/set-update-track-request';
import { UpdateTrack } from '../interfaces/update-track';

@Component({
  selector: 'app-set-update-track-dialog',
  templateUrl: './set-update-track-dialog.component.html',
  styleUrls: ['./set-update-track-dialog.component.css']
})
export class SetUpdateTrackDialogComponent implements OnInit {

  //#region properties
  private httpClient: HttpClient;
  private apiUrl: string;

  public setUpdateTrackForm: FormGroup;
  public formSubmitting: boolean;

  public updateTracks: UpdateTrack[];
  //#endregion

  //#region constructor
  constructor(@Inject(MAT_DIALOG_DATA) public data: SetUpdateTrack, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.UpdateTrack);
  }
  //#endregion
  
  //#region OnInit
  ngOnInit(): void {
    this.setUpdateTrackForm = new FormGroup({
      updateTrackSelect: new FormControl('', Validators.required),
    });
    this.loadUpdateTracks();
  }
  //#endregion

  //#region helpers
  /**
   * Load update tracks from database
   */
  loadUpdateTracks() {
    this.httpClient.get<ApiResponse<UpdateTrack[]>>(`${this.apiUrl}/`).subscribe({
      next: (result) => {
        this.updateTracks = result.body;
        this.setUpdateTrackForm.controls.updateTrackSelect.setValue(result.body[result.body.findIndex(x => x.id == this.data.currentUpdateTrack.id)]);
      },
      error: (e) => console.log(e)
    })
  }

  /**
   * Sets new update track and closes on success
   */
  updateTrack() {
    this.formSubmitting = true;

    var request: SetUpdateTrackRequest = {
      trackId: this.setUpdateTrackForm.controls.updateTrackSelect.value.id,
      key: this.data.key,
    }

    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/setUpdateTrack`, request).subscribe({
      next: (response) => {
        if (response.body) {
          FormHelpers.resetForm(this.setUpdateTrackForm);
          SnackBarHelper.sendSnackbar(this.snackBar, "Update Track Successfully Updated", "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, "Update Track Update Failed", "failure-snackbar");
        }
      }
    }).add(() => {
      this.formSubmitting = false;
    })
  }
  //#endregion
}
