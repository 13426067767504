<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">History Items</a>
    </div>

    <a *ngIf="historySetId == null || (itemsLoaded && dataSource.data.length == 0)">No history items exist</a>
    <mat-spinner color="primary" *ngIf="historySetId != null && !itemsLoaded" diameter="50" style="margin:0 auto"></mat-spinner>
    <div *ngIf="historySetId != null && itemsLoaded && dataSource.data.length > 0">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{formatTime(row.createDate)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef> User </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.user}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{truncateMessage(row.message)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button mat-icon-button color="primary" (click)="viewHistoryItem(row.id, row.message)" disableRipple>
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns;"></mat-row>
        </mat-table>

        <mat-paginator color="primary" [pageIndex]="paginatorObject.pageIndex"
            [pageSize]="paginatorObject.pageSize" [length]="paginatorObject.totalRecords"
            (page)="pageChanged($event)" aria-label="Select page" hidePageSize>
        </mat-paginator>
    </div>
</mat-card>