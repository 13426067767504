import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Application } from 'src/app/applications/interfaces/application.interface';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { PaginatedApiResponse } from 'src/app/common/Interfaces/paginated-api-repsonse.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { Module } from 'src/app/modules/interfaces/module.interface';
import { EditLicenseOrderData } from '../../interfaces/edit-license-order-data';
import { LicenseOrderUpdateModulesRequest } from '../../interfaces/license-order-update-modules-request';

@Component({
  selector: 'app-edit-modules',
  templateUrl: './edit-modules.component.html',
  styleUrls: ['./edit-modules.component.css']
})
export class EditModulesComponent implements OnInit {

  httpClient: HttpClient;
  apiUrl: string;
  applicationUrl: string;
  moduleUrl: string;
  formSubmitting: boolean = false;

  applications: Application[] = [];
  selectedModules: string[] = [];
  licenseModules: string[] = [];
  public step: string = "";

  public appsLoaded: boolean = false;
  public applicationModuleCount = {}

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditLicenseOrderData, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
    this.applicationUrl = ApiHelper.GetUrl(ApiPaths.Application);
    this.moduleUrl = ApiHelper.GetUrl(ApiPaths.Module);
  }

  ngOnInit(): void {


    this.loadApplications();
    this.loadModules();
  }

  /**
   * Updates modules and closes on success
   */
  editModules() {
    if (this.selectedModules.length > 0) {
      this.formSubmitting = true;

      const request: LicenseOrderUpdateModulesRequest = {
        orderId: this.data.licenseOrderId,
        modulesToAdd: this.selectedModules.filter(x => !this.licenseModules.includes(x)),
        modulesToRemove: this.licenseModules.filter(x => !this.selectedModules.includes(x))
      }

      console.log(request)

      this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/updateModules`, request).subscribe({
        next: (response) => {
          if (response.body) {
            SnackBarHelper.sendSnackbar(this.snackBar, `License Order Successfully Updated`, "success-snackbar");
            this.dialogRef.close(true);
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, "Edit Modules Failed", "failure-snackbar");
          }
        }
      }).add(() => {
        this.formSubmitting = false;
      })
    }
    else {
      SnackBarHelper.sendSnackbar(this.snackBar, "License Orders Must Have At Least One Module", "failure-snackbar");
    }
  }
  /**
   * get full list of applications from database
   */
  loadApplications() {
    this.httpClient.get<ApiResponse<Application[]>>(`${this.applicationUrl}`).subscribe(result => {
      this.applications = result.body;
      this.appsLoaded = true;
      this.applications.forEach(app => {
        this.applicationModuleCount[app.guid] = 0;
      })
    },
      error => console.error(error));
  }
/**
 * Load modules for license order
 */
  loadModules() {
    let paged_url = `${this.moduleUrl}?page=${1}&page_size=${-1}&license_order_id=${this.data.licenseOrderId}&depth=0`;

    this.httpClient.get<PaginatedApiResponse<Module[]>>(paged_url).subscribe(result => {
      result.body.forEach(element => {
        this.licenseModules.push(element.guid);
        this.selectModule({ source: { value: element.guid }, checked: true });
      });
    },
      error => console.error(error));
  }

  /**
   * Adds or removed module from selected list
   * @param event clicked checkbox
   */
  selectModule(event) {
    if (event.checked) {
      this.selectedModules.push(event.source.value)
      this.applicationModuleCount[this.applications[this.applications.findIndex(x => x.modules.findIndex(y => y.guid == event.source.value) != -1)].guid] += 1;
    }
    else {
      this.selectedModules.splice(this.selectedModules.indexOf(event.source.value), 1);
      this.applicationModuleCount[this.applications[this.applications.findIndex(x => x.modules.findIndex(y => y.guid == event.source.value) != -1)].guid] -= 1;
    }
  }

  /**
   * set expanded panel
   * @param guid guid of selected panel
   */
  setStep(guid: string) {
    this.step = guid;
  }

  //#region formatters
  /**
     * Checks if checkbox is selected
     * @param guid guid of checkbox
     * @returns true if selected, false if not
     */
  isSelected(guid: string) {
    return this.selectedModules.indexOf(guid) != -1;
  }
}
