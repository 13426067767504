import { HttpClient } from "@angular/common/http";

/** Service to download files */
export class DownloadService{

    private httpClient: HttpClient;

    constructor(http: HttpClient){
        this.httpClient = http;    
    }

    /**
     *  Download a file from the provided url
     *  @param file_url: String url to download the file from
     */
    download(file_url: string){
        this.httpClient.get(file_url, {observe: "response", responseType: 'blob'}).subscribe(result => {
            
            let fileName = result.headers.get('content-disposition');
            //Typical response
            //"attachment; filename=b1e06415-eb8b-4c20-e03e-08dab2ae7f79.lic; filename*=UTF-8''b1e06415-eb8b-4c20-e03e-08dab2ae7f79.lic"
            fileName = fileName.split(';')[1].split('=')[1];
            let file:Blob = result.body as Blob;

            let a = document.createElement('a');
            a.download = fileName;
            a.href = window.URL.createObjectURL(file);
            a.click();
        });
    }
}