<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Additional Claims</a>
        <button mat-icon-button class="add-icon-button" (click)="addAdditionalClaim($event)" disableRipple>
            <img src="../../assets/add_green.png" width="25px" height="25px" />
        </button>
    </div>

    <app-uid-spinner [visible]="!claimsLoaded"></app-uid-spinner>
    <div *ngIf="claimsLoaded">
        <mat-table [dataSource]="claimsDataSource">

            <ng-container matColumnDef="key">
                <mat-header-cell *matHeaderCellDef> Key </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.value}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.source}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button [disabled]="row.source=='Server'||row.source=='Order'" class="disabled" mat-icon-button matTooltip="Delete Claim" matTooltipPosition="right"
                        (click)="deleteAdditionalClaim($event, row.key)">
                        <img src="../../assets/trash_red.png" width="30px" height="30px" />
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="claimColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: claimColumns;">
            </mat-row>
        </mat-table>
    </div>
</mat-card>