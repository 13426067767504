import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { LoginService } from 'src/app/common/Helpers/login/login.service';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { environment } from 'src/environments/environment';
import { validatePasswordMatch } from '../directives/validate-password.directive';
import { PasswordResetRequest } from '../interfaces/password-reset-request';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  //#region properties
  httpClient: HttpClient;
  apiUrl: string

  passwordForm: FormGroup;

  usernameToReset: string;
  policyHolderUsername: string;
  routerState;
  //#endregion

  //#region constructors
  constructor(http: HttpClient, private router: Router, private loginService: LoginService, private snackBar: MatSnackBar) {
    this.httpClient = http;
    this.apiUrl = `${ApiHelper.GetUrl(ApiPaths.Login)}/resetPassword`;

    this.policyHolderUsername = loginService.getUsername;
    this.routerState = router.getCurrentNavigation().extras.state;

    if (this.routerState && this.routerState["usernameToReset"]) {
      this.usernameToReset = this.routerState["usernameToReset"]
    } else {
      this.usernameToReset = this.policyHolderUsername;
    }
  }
  //#endregion

  //#region OnInit
  ngOnInit() {
    this.passwordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.minLength(8)]),
      repeatPassword: new FormControl('', [Validators.minLength(8)])
    }, {
      validators: validatePasswordMatch
    });

  }
  //#endregion

  //#region events
  /**
   * resets user password
   * logs user out if own password was changed, goes to users page
   */
  OnSubmit() {
    if (this.passwordForm.valid) {
      const request: PasswordResetRequest = {
        policyHolderUsername: this.policyHolderUsername,
        usernameToReset: this.usernameToReset,
        newPassword: this.passwordForm.value.newPassword,
      }

      this.httpClient.post<ApiResponse<boolean>>(this.apiUrl, request).subscribe({
        next: (response) => {
          if (response.body) {
            if (this.usernameToReset !== this.policyHolderUsername) {
              SnackBarHelper.sendSnackbar(this.snackBar, "Password Successfully Updated", "success-snackbar")
              this.router.navigate(["/users"])
            } else {
              SnackBarHelper.sendSnackbar(this.snackBar, "Password Successfully Updated, Logging User Out", "success-snackbar")
              this.loginService.logout();
            }
          }
          else {
            SnackBarHelper.sendSnackbar(this.snackBar, "Password Reset Failed", "failure-snackbar")
          }
        }
      })
    }
    else {
      this.passwordForm.controls.repeatPassword.setErrors({ match: true })
    }
  }
  //#endregion
}
