import { PaginatedApiResponse } from "./paginated-api-repsonse.interface";
import { PageEvent } from '@angular/material/paginator';
import { ApiResponse } from "./api-response.interface";
import { environment } from "src/environments/environment";

/** Class used for paging operations and communicating user requests with query results */
export class PaginatorObject {
    totalPages: number;
    pageSize: number;
    totalRecords: number;
    pageIndex: number;

    public static readonly unpaginated = -1;

    constructor() {
        this.totalPages = 0;
        this.pageSize = environment.mediumPage;
        this.totalRecords = 0;
        this.pageIndex = 0;
    }

    /** 
     * Update the paginator via the page event thrown by the material paginator 
     * @param event page event from material paginator
    */
    onPageEvent(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }
    /**
     * Update total pages and record from api call
     * @param paginatedApiResponse Paginated Api response
     */
    onPaginatedResponse(paginatedApiResponse: PaginatedApiResponse<object[]>) {
        this.totalPages = paginatedApiResponse.totalPages;
        this.totalRecords = paginatedApiResponse.totalRecords;
    }
    /** 
     * Returns the page query corresponding to the parameter in the paginator 
     * @returns Page query to be injected into a URL
    */
    get pageQuery() {
        let adjusted_page = this.pageIndex + 1; //The paginator is 0 based, but the api is 1 base
        let query = `page=${adjusted_page}&page_size=${this.pageSize}`;
        return query;
    }
}