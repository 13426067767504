<mat-grid-list cols="2" rowHeight="250">
    <mat-grid-tile>
        <mat-card class="overview-stats-card">
            <a class="view-information-table-title"># Activated Licenses</a>
            <div class="overview-content">
                <a *ngIf="productsLoaded">{{numActivated}}</a>
                <app-uid-spinner [visible]="!productsLoaded"></app-uid-spinner>
            </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="overview-stats-card">
            <a class="view-information-table-title"># Recently Checked In</a>
            <div class="overview-content">
                <a *ngIf="productsLoaded">{{checkedIn}}</a>
                <app-uid-spinner [visible]="!productsLoaded"></app-uid-spinner>
            </div>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>

<div class="vertical-spacer-large"></div>

<mat-table [dataSource]="productsDataSource" *ngIf="productsLoaded">
    <ng-container matColumnDef="product">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Product</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Total Licenses</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.totalAvailable}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="recent">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Recently Checked In</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.recentlyCheckedIn}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="release">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Latest Release</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.release}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="productsColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: productsColumns;">
    </mat-row>
</mat-table>

<div class="vertical-spacer-large"></div>
<a class="section-header-large">Recent Activity</a>
<div class="vertical-spacer"></div>
<mat-table [dataSource]="recentActivityDataSource" *ngIf="recentActivityLoaded">
    <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.company}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.action}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="product">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Product</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.product}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Version</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.version}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastCheckIn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Check In</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.lastCheckIn}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="license">
        <mat-header-cell *matHeaderCellDef style="flex: 5%;">License</mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex: 5%;">{{row.license}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" [routerLink]="['./licenses/',row.license]" disableRipple>
                <mat-icon>search</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="recentActivityColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: recentActivityColumns;">
    </mat-row>
</mat-table>

<div class="vertical-spacer-large"></div>

<div class="view-information-toolbar">
    <a class="section-header-large center-text">Active Versions</a>
    <span class="spacer"></span>
    <section class="short-section">
        <mat-form-field appearance="outline">
            <mat-label>App</mat-label>
            <mat-select (selectionChange)="switchSelection($event)">
                <mat-option *ngFor="let app of releasedProductsList" [value]="app">{{app.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>
</div>
<app-uid-spinner [visible]="!activeVersionsLoaded"></app-uid-spinner>
<mat-table [dataSource]="activeVersionsDataSource" *ngIf="activeVersionsLoaded">
    <ng-container matColumnDef="active-version">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Version</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.version}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="track">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Track</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.track}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Count</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.details?.length?? '-'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="summary">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" (click)="showInfo(row)" disableRipple>
                <mat-icon svgIcon="uid-summary"></mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="activeVersionsColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: activeVersionsColumns;">
    </mat-row>
</mat-table>
<div class="vertical-spacer"></div>
