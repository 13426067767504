<mat-toolbar class="section-header">
    <a class="section-header-title">Edit {{user.displayName}}</a>
</mat-toolbar>

<mat-divider></mat-divider>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="editUserForm" (ngSubmit)="OnSubmit()" novalidate>
    <fieldset [disabled]="formSubmitting">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Display Name</mat-label>
                <input matInput formControlName="displayName" required>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
        </section>
        <section>
            <button mat-raised-button disableRipple [routerLinkActive]="['link-active']"
                [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/resetPassword']"
                [state]="{usernameToReset: user.username}" class="submit-button edit-button">Reset Password
            </button>
        </section>
        <section>
            <button mat-raised-button disableRipple class="submit-button"
                color="primary" type="submit">Update
            </button>
        </section>
    </fieldset>
</form>