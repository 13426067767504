/** 
 * Environment injection for the jenkins build/deploys 
 * ng build --configuration="jenkins"
*/
export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  baseUrl: `http://jenkins.uidevices.com:4501/api`,
  shortPage: 5,
  mediumPage: 25,
  longPage:50,
  requireHttps: false,
};