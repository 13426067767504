import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-uid-spinner',
  templateUrl: './uid-spinner.component.html',
  styleUrls: ['./uid-spinner.component.css']
})
/**
 * Standard spinner
 */
export class UIDSpinnerComponent {
  /** Spinner visibility */
  @Input() visible = true;
  /** Optional diameter of spinner */
  @Input() size = 50;
}
