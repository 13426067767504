import { FormControl, FormGroup } from "@angular/forms";
/**
 * Class to manage forms
 */
export class FormHelpers {
    /**
    ** Reset all form fields
    */
    static resetForm(form: FormGroup) {
        (Object as any).values(form.controls).forEach((control: FormControl) => {
            control.setValue(null);
            control.markAsUntouched();
            control.markAsPristine();
            control.setErrors(null);
        });
    }
}