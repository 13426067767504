<mat-drawer-container>
    <mat-drawer #drawer mode="side" class="side-drawer" [opened]="toggle">
        <div class="sidenav-content">
            <button mat-button disableRipple class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.Home)"
                [ngClass]="{'selected-page-button': selectedButton == routes.Home}">Home</button>
            <button mat-button disableRipple class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.Orders)"
                [ngClass]="{'selected-page-button': selectedButton == routes.Orders}">License Orders</button>
            <button mat-button disableRipple class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.Licenses)"
                [ngClass]="{'selected-page-button': selectedButton == routes.Licenses}">Licenses</button>
            <button mat-button disableRipple  class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.LicenseActivation)"
                [ngClass]="{'selected-page-button': selectedButton == routes.LicenseActivation}">Offline
                Activation</button>
            <button mat-button disableRipple class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.Companies)"
                [ngClass]="{'selected-page-button': selectedButton == routes.Companies}">Companies</button>
            <button mat-button disableRipple class="side-button" *ngIf="loggedIn" (click)="setClicked(routes.Users)"
                [ngClass]="{'selected-page-button': selectedButton == routes.Users}">Users</button>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>