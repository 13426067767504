<a mat-dialog-title><mat-icon color="accent" class="dialog-icon">warning</mat-icon>Disable License Order</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<mat-dialog-content>
    <a class="bolded">Disabling the license order will deactivate all licenses.<br>This action cannot be undone. Are you sure you want to disable this order?</a>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" (click)="disableLicense()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>