<a mat-dialog-title>{{versionName}} ({{data.activeVersion.version}}) Summary</a>
<mat-dialog-content>
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Customer</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.customer}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="license">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 5%;">License</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 5%;">{{row.license}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="last-check-in">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Last Check In</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.lastCheckIn}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" (click)="loadLicense(row)" disableRipple>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;">
        </mat-row>
    </mat-table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Close</button>
</mat-dialog-actions>