<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">Add Team</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle">Add a Team to a company</a>
</mat-toolbar-row>
<mat-divider></mat-divider>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="createTeamForm" (ngSubmit)="OnSubmit()" (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="formSubmitting">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Team Name</mat-label>
                <input matInput formControlName="teamName" maxlength="40" />
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="teamDescription"></textarea>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Company</mat-label>
                <input matInput formControlName="companyField" [value]="selectedCompany.name"
                    (focus)="selectCompany($event)" readonly required />
            </mat-form-field>
            <button mat-icon-button class="align-form-field " (click)="selectCompany($event)"
                (keydown.Enter)="selectCompany($event)" type="button" color="primary" disableRipple>
                <mat-icon>search</mat-icon>
            </button>
        </section>
        <section>
            <button mat-raised-button class="submit-button" color="primary" type="submit">Add</button>
        </section>
    </fieldset>
</form>