import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HistoryItem } from '../../interfaces/history-item';

@Component({
  selector: 'app-view-history-item',
  templateUrl: './view-history-item.component.html',
  styleUrls: ['./view-history-item.component.css']
})
export class ViewHistoryItemComponent implements OnInit {

  historyItem: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: HistoryItem) {
    try {
      this.historyItem = JSON.stringify(JSON.parse(this.data.message), null, 2);
    } catch {
      this.historyItem = this.data.message;
    }
  }

  ngOnInit(): void {
  }
}
