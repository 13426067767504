<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">License Transfer - {{this.licenseGuid}}</a>
</mat-toolbar>
<mat-divider></mat-divider>

<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="transferLicenseForm">
    <section>
        <mat-form-field (click)="selectCompany($event)" appearance="outline">
            <mat-label>Company</mat-label>
            <input matInput formControlName="companyField" [value]="selectedCompany.name" readonly required />
            <mat-error>Company has no teams!</mat-error>
        </mat-form-field>
        <button mat-icon-button class="align-form-field" (click)="selectCompany($event)" type="button"
            color="primary" disableRipple>
            <mat-icon>search</mat-icon>
        </button>
    </section>
    <section>
        <mat-form-field style="margin-left: 1em;" appearance="outline">
            <mat-label>Team</mat-label>
            <mat-select required formControlName="teamId" (selectionChange)="onTeamSelect()">
                <mat-option *ngFor="let team of teams" [value]="team.id">
                    {{team.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>
    <section>
        <mat-form-field appearance="outline">
            <mat-label>Orders</mat-label>
            <mat-select required formControlName="orderId" (selectionChange)="onOrderSelect()">
                <mat-option *ngFor="let licenseOrder of licenseOrders" [value]="licenseOrder.id">
                    {{licenseOrder.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>
    <div *ngIf="newOrderFormEnabled">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput formControlName="orderNameField" required />
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput formControlName="orderDescriptionField" required />
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Reference Number</mat-label>
                <input matInput formControlName="orderReferenceNumberField" required />
            </mat-form-field>
        </section>
    </div>
    <section>
        <button mat-raised-button class="submit-button" color="primary" (click)="onTransferClick()">Transfer License</button>
    </section>
</form>