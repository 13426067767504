import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Company } from './Interfaces/company.interface';
import { ApiPaths } from '../enums/api-paths';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorObject } from '../common/Interfaces/paginator-object';
import { PageEvent } from '@angular/material/paginator';
import { PaginatedApiResponse } from '../common/Interfaces/paginated-api-repsonse.interface';
import { ApiHelper } from '../common/Helpers/api-helper';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})

/**
 * Component used to display a list of companies 
 */
@Injectable()
export class CompaniesComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;
  
  public loaded = false;
  public companies: Company[];
  public companiesDataSource: MatTableDataSource<Company> = new MatTableDataSource();
  public columns: string[] = ['name', 'info'];

  public paginatorObject: PaginatorObject = new PaginatorObject();

  constructor(http: HttpClient) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.Company);
  }

  ngOnInit() {
    this.loadCompanies();
  }

  /**
   * Update companies list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadCompanies();
  }

  /** Retrieve all companies from database */
  loadCompanies() {
    let url = `${this.apiUrl}?${this.paginatorObject.pageQuery}`
    this.loaded = false;

    this.httpClient.get<PaginatedApiResponse<Company[]>>(url).subscribe(result => {
      this.companies = result.body;
      this.companiesDataSource.data = result.body;
      this.paginatorObject.onPaginatedResponse(result);
      this.loaded = true;
    },
      error => console.error(error));
  }
}
