import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { PaginatedApiResponse } from '../common/Interfaces/paginated-api-repsonse.interface';
import { ApiPaths } from '../enums/api-paths';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { PaginatorObject } from '../common/Interfaces/paginator-object';
import { LicenseOrder } from './interfaces/license-order.interface';
import { ApiHelper } from '../common/Helpers/api-helper';

@Component({
  selector: 'app-license-orders',
  templateUrl: './license-orders.component.html',
  styleUrls: ['./license-orders.component.css']
})

/** Component used to display a paginated list of license orders */
@Injectable()
export class LicenseOrdersComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  public licenseOrders: LicenseOrder[];
  public licenseOrderDataSource: MatTableDataSource<LicenseOrder> = new MatTableDataSource();
  public columns: string[] = ['name','referenceNumber', 'description', 'info'];

  public paginatorObject: PaginatorObject = new PaginatorObject();
  public loaded = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(http: HttpClient) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
  }

  ngOnInit() {
    this.loadLicenseOrders();
  }

  /**
   * Update license order list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadLicenseOrders();
  }

  /** Loads new license order list from database  */
  loadLicenseOrders() {
    let paged_url = `${this.apiUrl}?${this.paginatorObject.pageQuery}`;
    this.loaded = false;

    this.httpClient.get<PaginatedApiResponse<LicenseOrder[]>>(paged_url).subscribe(result => {
      this.licenseOrders = result.body;
      this.paginatorObject.onPaginatedResponse(result);
      this.licenseOrderDataSource.data = result.body;
      this.loaded = true;
    },
      error => console.error(error));
  }

}
