<mat-card class="view-information-card login-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Welcome to UID Licensing App</a>
        <span class="spacer"></span>
    </div>
    <app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

    <form [formGroup]="loginForm" (ngSubmit)="OnSubmit()">
        <fieldset [disabled]="formSubmitting">
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username" />
                </mat-form-field>
            </section>
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password" />
                </mat-form-field>
            </section>
            <section>
                <button mat-raised-button class="submit-button" type="submit" color="primary"
                    disableRipple>Login</button>
            </section>
        </fieldset>
    </form>
</mat-card>