<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">License - {{license.guid}}</a>
    <span class="spacer"></span>
    <a class="section-header-title" [ngStyle]="{'color': color}">{{status}}</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle"> License Order - {{license.licenseOrderId ? licenseOrder.name : "N/A"}}</a>
    <button mat-icon-button *ngIf="license.licenseOrderId" color="primary"
        [routerLink]="['/licenseOrders',license.licenseOrderId]" disableRipple>
        <mat-icon>search</mat-icon>
    </button>
</mat-toolbar-row>
<mat-divider></mat-divider>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Details</a>
        <span class="spacer"></span>
    </div>
    <div class="flex">
        <mat-card-content class="stats-card-content">
            <a class="bolded">Activation Date</a>
            <a class="bordered">{{ExpirationDateString(license.activatedOn)}}</a>

            <span class="spacer"></span>
            <a class="bolded">Last Check In</a>
            <a class="bordered">{{LastCheckInString(license.lastStatusUpdate)}}</a>
        </mat-card-content>
        <mat-card-content class="stats-card-content">
            <a class="bolded">Expiration Date</a>
            <a class="bordered">{{ExpirationDateString(license.expirationDate)}}</a>
            <div *ngIf="daysUntilExpiration != null">
                <a *ngIf="daysUntilExpiration > 0">Expires in {{daysUntilExpiration}} days</a>
                <a class="warning-text" *ngIf="daysUntilExpiration <= 0">Expired</a>
            </div>

            <a class="bolded">Update Track</a>
            <div class="flex">
                <a class="bordered">{{currentUpdateTrack.name}}</a>
                <button mat-icon-button color="primary" class="inline-icon-button" (click)="setUpdateTrack($event)"
                    disableRipple>
                    <mat-icon svgIcon="uid_edit"></mat-icon>
                </button>
            </div>
        </mat-card-content>
    </div>
</mat-card>

<app-additional-claim [licenseGuid]="license.guid" [license]="license"></app-additional-claim>

<mat-card *ngIf="license.activated" class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Last Status Message</a>
        <span class="spacer"></span>
    </div>
    <div class="flex">
        <mat-card-content>
            <pre>{{StatusString()}}</pre>
        </mat-card-content>
    </div>
</mat-card>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Modules</a>
    </div>

    <mat-spinner color="primary" *ngIf="!modulesLoaded" diameter="50" style="margin:0 auto"></mat-spinner>
    <div *ngIf="modulesLoaded">
        <mat-table [dataSource]="modulesDataSource">
            <ng-container matColumnDef="application">
                <mat-header-cell *matHeaderCellDef style="flex: 5%;"> Application </mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: 5%;"> {{row.applicationName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Module </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="moduleColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: moduleColumns;">
            </mat-row>
        </mat-table>

        <mat-paginator color="primary" [pageIndex]="modulePaginatorObject.pageIndex"
            [pageSize]="modulePaginatorObject.pageSize" [length]="modulePaginatorObject.totalRecords"
            (page)="modulePageChanged($event)" aria-label="Select page" hidePageSize>
        </mat-paginator>
    </div>
</mat-card>

<app-history-items [historySetId]="license.historySetId" [reload]="reloadHistoryFlag"></app-history-items>