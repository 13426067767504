import { MatSnackBar } from "@angular/material/snack-bar";
/**
 * Class for sending snackbars
 */
export class SnackBarHelper {

    /**
   * Send snackbar with provided snackbar and panel class
   * @param message Text for the snackbar to show
   * @param panelClass CSS class for the snackbar
   */
  static sendSnackbar(snackBar: MatSnackBar, message: string, panelClass: string) {
    snackBar.open(message, null, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [panelClass],
      duration: 3000
    });
  }
}