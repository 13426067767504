import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAdditionalClaimComponent } from './dialogs/add-additional-claim/add-additional-claim.component';
import { GetOrderClaims } from './interfaces/get-order-claims';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { HttpClient } from '@angular/common/http';
import { AdditionalClaimsResponse } from './interfaces/additional-claims-response';
import { GetAllClaims } from './interfaces/get-all-claims';
import { License } from '../licenses/interfaces/license.interface';
import { AdditionalClaim } from './interfaces/additional-claim';
import { DeleteAdditionalClaimComponent } from './dialogs/delete-additional-claim/delete-additional-claim.component';
import { GetLicenseClaims } from './interfaces/get-license-claims';

@Component({
  selector: 'app-additional-claim',
  templateUrl: './additional-claim.component.html',
  styleUrls: ['./additional-claim.component.css']
})
export class AdditionalClaimComponent implements OnInit {
  // Inputs from parent components
  @Input() orderId: number;
  @Input() licenses: License[];
  @Input() license: License;
  @Input() licenseGuid: string;

  httpClient: HttpClient;
  claimsUrl: string;

  claims: AdditionalClaim[] = [{id:0, key:"-", value:"-", source:"-"}];
  claimsDataSource: AdditionalClaim[];
  claimColumns: string[];
  claimsLoaded: boolean = false;
  claimNames: string[] = [];

  publicKeyCount: number = 0;
  allPublicKeysSet: boolean = false;
  allowEncryption: boolean;
  isOrder: boolean = false;

  constructor(private dialog: MatDialog, http: HttpClient) {
    this.httpClient = http;
    this.claimsUrl = `${ApiHelper.GetUrl(ApiPaths.License)}/${ApiPaths.AdditionalClaim}`;
  }

  ngOnInit(): void {
  }

  // Fire claim loading after the @Input's have been passed through
  ngOnChanges(): void {
    this.loadAdditionalClaims();
    this.assertEncryptionAvailability();
    this.assertOrderType();
  }
  /*
   * Opens the add additional claim dialog and passes through order and license details
   */
  addAdditionalClaim(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(AddAdditionalClaimComponent, {
      data: {
        ClaimOrderId: this.orderId,
        ClaimLicenseGuid: this.licenseGuid,
        AllowEncryption: this.allowEncryption,
        AllPublicKeysSet: this.allPublicKeysSet,
        claimKeys: this.claimNames
      },
      autoFocus: false,
      panelClass: "view-panel"
    });
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadAdditionalClaims();
    });
  }
  /*
   * Opens the confirm delete claim dialog and passes through license and claim details
   */
  deleteAdditionalClaim(event, key: string) {
    event.preventDefault();

    let dialogRef = this.dialog.open(DeleteAdditionalClaimComponent, {
      data: {
        ClaimOrderId: this.orderId,
        ClaimLicenseGuid: this.licenseGuid,
        Key: key
      },
      autoFocus: false,
      panelClass: "view-panel"
    });
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadAdditionalClaims();
    });
  }
  /*
   * Gets either order claims or all claims, based on information passed through by parent component
   */
  loadAdditionalClaims() {
    if (this.orderId != null && this.licenseGuid == null) {
      this.getOrderClaims();

      // These are necessary for knowing when an order claim will overwrite a license claim
      this.licenses.forEach(license => {
        this.getLicenseClaimKeys(license.guid);
      });
    } else if (this.orderId == null && this.licenseGuid != null && this.licenseGuid.includes("-")) {
      this.getAllClaims();

      this.getLicenseClaimKeys(this.licenseGuid);
    }
  }
  /*
   * Gets all of the keys from a license
   */
  getLicenseClaimKeys(licenseGuid: string) {
    const request: GetLicenseClaims = {
      LicenseGuid: licenseGuid
    }
    this.httpClient.post<ApiResponse<AdditionalClaimsResponse>>(`${this.claimsUrl}/licenseClaims`, request).subscribe({
      next: (response) => {
        if (response.body.additionalClaims.length > 0) {
          response.body.additionalClaims.forEach(claim => {
            this.claimNames.push(claim.key);
          });
        }
      }
    });
  }
  /*
   * Gets the additional claims for a license order
   */
  getOrderClaims() {
    const request: GetOrderClaims = {
      LicenseOrderId: this.orderId
    }
    this.httpClient.post<ApiResponse<AdditionalClaimsResponse>>(`${this.claimsUrl}/orderClaims`, request).subscribe({
      next: (response) => {
        if (response.body.additionalClaims.length > 0) {
          this.claims = response.body.additionalClaims;
          this.claims.forEach(claim => {
            // Push order claims names for overwrite checking
            this.claimNames.push(claim.key);
          });
        } else {
          this.claims = [];
        }
        this.claimsDataSource = this.claims;
      }
    });
    this.claimsLoaded = true;
  }
  /*
   * Gets the additional claims for a license
   */
  getAllClaims() {
    const request: GetAllClaims = {
      LicenseGuid: this.licenseGuid
    }
    this.httpClient.post<ApiResponse<AdditionalClaimsResponse>>(`${this.claimsUrl}/allClaims`, request).subscribe({
      next: (response) => {
        if (response.body.additionalClaims.length > 0) {
          this.claims = response.body.additionalClaims;
        } else {
          this.claims = [];
        }
        this.claimsDataSource = this.claims;
      }
    });
    this.claimsLoaded = true;
  }
  /*
   * Determines if encryption is available based on the public key state of all of the licenses
   */
  assertEncryptionAvailability() {
    if (this.licenses != null) {
      this.licenses.forEach(license => {
        if (license.publicKey != null) {
          this.publicKeyCount++;
        }
      });
      this.allowEncryption = this.publicKeyCount > 0;
      this.allPublicKeysSet = this.publicKeyCount == this.licenses.length;
    } else if (this.license != null) {
      this.allowEncryption = this.license.publicKey != null;
      this.allPublicKeysSet = this.license.publicKey != null;
    }
  }
  /*
   * Determines whether to show columns for a license or order page
   */
  assertOrderType() {
    this.isOrder = this.orderId != null && this.licenseGuid == null;
    if (this.isOrder) {
      this.claimColumns = ['key', 'value', 'delete'];
    } else {
      this.claimColumns = ['key', 'value', 'source', 'delete'];
    }
  }
}
