import { Injectable, OnDestroy } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'

/** Navigation service to go back a page */
@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  //#region properties
  private history: string[] = []
  private subscription;
  //#endregion

  //#region constructors
  constructor(private router: Router) {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }
  //#endregion

  //#region OnDestroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  //#endregion

  //#region helpers
  /** Remove current page from history and return to previous one */
  back(): void {
    this.history.pop()
    const url = this.history.pop();
    if (this.history.length > 0) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl('/');
    }
  }
  //#endregion
}