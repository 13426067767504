<mat-toolbar class="section-header">
    <a class="section-header-title">Users</a>
    <button mat-raised-button class="add-button" [routerLink]="['./add']" disableRipple>Add User</button>
</mat-toolbar>

<app-uid-spinner [visible]="!loaded"></app-uid-spinner>

<div *ngIf="loaded">
    <mat-table [dataSource]="usersDataSource">
        <ng-container matColumnDef="displayName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.displayName}} </mat-cell>
        </ng-container>
        <!-- User details page added in LIC-78 -->
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" [routerLink]="['./',row.id]" disableRipple>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;">
        </mat-row>
    </mat-table>

    <mat-paginator [pageIndex]="paginatorObject.pageIndex" [pageSize]="paginatorObject.pageSize"
        [length]="paginatorObject.totalRecords" (page)="pageChanged($event)" aria-label="Select page" hidePageSize>
    </mat-paginator>
</div>