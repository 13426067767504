import { Component, OnInit, Inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { OrderClaimRequest } from '../../interfaces/order-claim-request';
import { LicenseClaimRequest } from '../../interfaces/license-claim-request';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmOverwriteRequest } from '../../interfaces/confirm-overwrite-request';

@Component({
  selector: 'app-confirm-overwrite',
  templateUrl: './confirm-overwrite.component.html',
  styleUrls: ['./confirm-overwrite.component.css']
})
export class ConfirmOverwriteComponent implements OnInit {
  httpClient: HttpClient;
  apiUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmOverwriteRequest, private dialog: MatDialog, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = `${ApiHelper.GetUrl(ApiPaths.License)}/${ApiPaths.AdditionalClaim}`;
  }

  ngOnInit(): void {
  }
  /*
   * Decides whether to add an additional claim to an order or license based on parent parameters
   */
  addAdditionalClaim() {
    if (this.data.ClaimOrderId != null && this.data.ClaimLicenseGuid == null) {
      this.addOrderClaim();
    } else if (this.data.ClaimOrderId == null && this.data.ClaimLicenseGuid != null) {
      this.addLicenseClaim();
    } else {
      SnackBarHelper.sendSnackbar(this.snackBar, "Adding an additional claim requires either a License Order ID or a License Guid", "failure-snackbar");
    }
  }
  /*
   * Adds an additional claim to a license order
   */
  addOrderClaim() {
    const request: OrderClaimRequest = {
      LicenseOrderId: this.data.ClaimOrderId,
      Key: this.data.Key,
      Value: this.data.Value,
      IsEncrypted: this.data.Encrypted
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/addLicenseOrderClaim`, request).subscribe({
      next: (response) => {
        if (response.body) {
          SnackBarHelper.sendSnackbar(this.snackBar, `Additional claim successfully added`, "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        }
        this.dialog.closeAll();
      }
    });
  }
  /*
   * Adds an additional claim to a license
   */
  addLicenseClaim() {
    const request: LicenseClaimRequest = {
      LicenseGuid: this.data.ClaimLicenseGuid,
      Key: this.data.Key,
      Value: this.data.Value,
      IsEncrypted: this.data.Encrypted
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/addLicenseClaim`, request).subscribe({
      next: (response) => {
        if (response.success) {
          SnackBarHelper.sendSnackbar(this.snackBar, `Additional claim successfully added`, "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        }
        this.dialog.closeAll();
      }
    });
  }
}
