import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { ExtendLicenseOrder } from '../../interfaces/extend-license-order';
import { LicenseOrderAddLicensesRequest } from '../../interfaces/license-order-add-licenses-request';

@Component({
  selector: 'app-add-licenses',
  templateUrl: './add-licenses.component.html',
  styleUrls: ['./add-licenses.component.css']
})
export class AddLicensesComponent implements OnInit {

  httpClient: HttpClient;
  apiUrl: string;

  addLicensesForm: FormGroup;
  formSubmitting: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExtendLicenseOrder, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
  }

  ngOnInit(): void {
    this.addLicensesForm = new FormGroup({
      quantity: new FormControl('', [Validators.min(1), Validators.max(500)]),
      expirationDate: new FormControl(this.data.currentExpirationDate, Validators.required),
    });
  }

  /**
   * Adds licenses and closes on success
   */
  addLicenses() {
    if (this.addLicensesForm.valid) {
      this.formSubmitting = true;

      const request: LicenseOrderAddLicensesRequest = {
        orderId: this.data.licenseOrderId,
        expirationDate: this.addLicensesForm.value.expirationDate,
        count: this.addLicensesForm.value.quantity,
      }

      this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/addLicenses`, request).subscribe({
        next: (response) => {
          if (response.body) {
            FormHelpers.resetForm(this.addLicensesForm);
            SnackBarHelper.sendSnackbar(this.snackBar, `${request.count} Licenses Successfully Added`, "success-snackbar");
            this.dialogRef.close(true);
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, "Add Licenses Failed", "failure-snackbar");
          }
        }
      }).add(() => {
        this.formSubmitting = false;
      })
    }
  }
}