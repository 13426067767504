import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company } from '../Interfaces/company.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorObject } from 'src/app/common/Interfaces/paginator-object';
import { DialogRef } from '@angular/cdk/dialog';
import { PaginatedApiResponse } from 'src/app/common/Interfaces/paginated-api-repsonse.interface';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.css']
})
/**
 * Component used to add a new company to the system
 */
export class CompanySelectionComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  public loaded: boolean;
  public companies: Company[];
  public companiesDataSource: MatTableDataSource<Company> = new MatTableDataSource();
  public columns: string[] = ['key'];

  public selectedCompany = {
    id: null,
    name: null,
  }
  public paginatorObject: PaginatorObject = new PaginatorObject();

  constructor(http: HttpClient, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.Company);
  }

  ngOnInit() {
    this.loadCompanies();
  }

  /**
   * Update companies list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadCompanies();
  }

  /** Retrieve all companies from database */
  loadCompanies() {
    let url = `${this.apiUrl}?${this.paginatorObject.pageQuery}`
    this.loaded = false;

    this.httpClient.get<PaginatedApiResponse<Company[]>>(url).subscribe(result => {
      this.companies = result.body;
      this.companiesDataSource.data = result.body;
      this.paginatorObject.onPaginatedResponse(result);
      this.loaded = true;
    },
      error => console.error(error));
  }

  /**
   * set company to return
   * @param row table row to select
   */
  selectCompany(row) {
    this.selectedCompany = row;
  }

  /**
   * Method to call when exiting dialog without selecting a company
   */
  OnCancel() {
    this.dialogRef.close(null);
  }
}