import { Component, OnInit, Input } from '@angular/core';
import { PaginatorObject } from '../common/Interfaces/paginator-object';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryItem } from './interfaces/history-item';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ApiPaths } from '../enums/api-paths';
import { ApiHelper } from '../common/Helpers/api-helper';
import { PageEvent } from '@angular/material/paginator';
import { PaginatedApiResponse } from '../common/Interfaces/paginated-api-repsonse.interface';
import { ViewHistoryItemComponent } from './dialogs/view-history-item/view-history-item.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-history-items',
  templateUrl: './history-items.component.html',
  styleUrls: ['./history-items.component.css']
})
export class HistoryItemsComponent implements OnInit {
  // Input from parent component
  @Input() historySetId: number;
  @Input() reload: boolean;

  dataSource: MatTableDataSource<HistoryItem> = new MatTableDataSource();
  paginatorObject: PaginatorObject = new PaginatorObject();
  columns: string[] = ['date', 'user', 'message', 'expand'];
  httpClient: HttpClient;
  historyUrl: string;
  itemsLoaded: boolean = false;

  constructor(private dialog: MatDialog, http: HttpClient) {
    this.httpClient = http;
    this.historyUrl = ApiHelper.GetUrl(ApiPaths.History);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.loadHistoryItems();
  }

  /**
   * Event fired when the page is changed
   * @param event The page change event
   */
  pageChanged(event: PageEvent) {
    this.paginatorObject.onPageEvent(event);
    this.loadHistoryItems();
  }
  /**
   * Truncates a message to the specified number of characters
   * @param input The string to truncate
   * @param characterCount The length of the truncated string in characters
   * @returns The truncated string
   */
  truncateMessage(input: string, characterCount: number = 100): string {
    return input != null && input.length > characterCount ? input.substring(0, characterCount) : input;
  }
  /**
   * Loads the history items into the table
   */
  loadHistoryItems() {
    if (this.historySetId != null) {
      this.itemsLoaded = false;
      this.httpClient.get<PaginatedApiResponse<HistoryItem[]>>(`${this.historyUrl}?ids=${this.historySetId}&${this.paginatorObject.pageQuery}`).subscribe(response => {
        if (response.success) {
          this.paginatorObject.onPaginatedResponse(response);
          this.dataSource.data = response.body;
        } else {
          this.dataSource.data = [];
        }
        this.itemsLoaded = true;
      });
    }
  }
  /**
   * Opens the view history item panel
   * @param id The id of the history item
   * @param message The message to display in the panel
   */
  viewHistoryItem(id: number, message: string) {
    let dialogRef = this.dialog.open(ViewHistoryItemComponent, {
      data: {
        id: id,
        message: message
      },
      autoFocus: false,
      panelClass: "view-panel"
    });
  }
  /**
   * Converts the date to a more readable format
   * @param date The date to format
   */
  formatTime(date: Date) {
    try {
      return formatDate(date, "MM-dd-yyy hh:mm:ss a", "en")
    } catch {
      return "-";
    }
  }
}
