import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarHelper } from "src/app/common/Helpers/snackbar-helper";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiPaths } from "src/app/enums/api-paths";
import { ApiHelper } from "src/app/common/Helpers/api-helper";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Team } from 'src/app/teams/interfaces/teams.interface';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { Company } from 'src/app/companies/Interfaces/company.interface';
import { CompanySelectionComponent } from 'src/app/companies/company-selector/company-selector.component';
import { LicenseOrder } from 'src/app/license-orders/interfaces/license-order.interface';
import { TransferLicenseRequest } from '../interfaces/transfer-license-request';
import { LicenseOrderCreateRequest } from 'src/app/license-orders/interfaces/license-order-create-request.interface';
import { LicenseOrderCreateResponse } from 'src/app/license-orders/interfaces/license-order-create-response.interface';
import { License } from '../interfaces/license.interface';

@Component({
  selector: 'app-transfer-license',
  templateUrl: './transfer-license.component.html',
  styleUrls: ['./transfer-license.component.css']
})
export class TransferLicenseComponent implements OnInit {
  private httpClient: HttpClient;
  private licenseUrl: string;
  private teamUrl: string;
  private licenseOrderUrl: string;
  licenseGuid: string;
  transferLicenseForm: FormGroup;
  formSubmitting: boolean = false;
  teams: Team[] = [];
  licenseOrders: LicenseOrder[] = [];
  errorMessage: string;

  newOrderFormEnabled: boolean = false;
  newOrder: LicenseOrder = {
    name: "(Create New Order)",
    id: -1,
    referenceNumber: '',
    description: '',
    modulePreset: {
      id: 0,
      name: '',
      description: '',
      moduleGuids: ['']
    },
    expirationDate: '',
    team: {
      id: 0,
      name: '',
      description: '',
      company: undefined
    },
    historySetId: 0,
    licenses: [{
      expirationDate: '',
      guid: '',
      referenceNumber: '',
      activatedOn: '',
      activated: false,
      mode: '',
      lastStatusUpdate: '',
      modulePreset: {
        id: 0,
        name: '',
        description: '',
        moduleGuids: ['']
      },
      modules: [{
        guid: '',
        name: '',
        description: '',
        applicationGuid: '',
        applicationName: ''
      }],
      licenseOrderId: 0,
      enabled: false,
      status: '',
      publicKey: '',
      historySetId: 0
     }]
  }

  selectedCompany: Company = {
    name: "",
    id: 0,
  }

  constructor(http: HttpClient, public dialog: MatDialog, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) {
    this.httpClient = http;
    this.licenseUrl = ApiHelper.GetUrl(ApiPaths.License);
    this.licenseOrderUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
    this.teamUrl = ApiHelper.GetUrl(ApiPaths.Team);
    this.licenseGuid = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.transferLicenseForm = new FormGroup({
      companyField: new FormControl(''),
      teamId: new FormControl('', Validators.required),
      orderId: new FormControl(''),
      orderNameField: new FormControl(''),
      orderDescriptionField: new FormControl(''),
      orderReferenceNumberField: new FormControl('')
    });
  }

  /**
   * Opens company selection dialog
   */
  selectCompany(event: Event) {
    event.preventDefault();
    this.disableOrders();

    let dialogRef = this.dialog.open(CompanySelectionComponent, {
      autoFocus: false,
      panelClass: "company-panel"
    })
    dialogRef.afterClosed().subscribe(company => {
      if (company != null) {
        this.selectedCompany = company;
        this.transferLicenseForm.controls.companyField.setErrors(null);
        this.onCompanySelect(company.id)
      }
    },
      error => console.log(error));
  }
  /**
   * Populates teams list based on selected company
   * @param id company's team to load
   */
  onCompanySelect(id: number) {
    this.httpClient.get<ApiResponse<Team[]>>(`${this.teamUrl}?company_id=${id}`).subscribe(result => {
      this.teams = result.body;
      if (this.teams.length == 0)
        this.transferLicenseForm.controls.companyField.setErrors({ "teams": "Company has no teams" })
    },
      error => console.error(error));
  }
  /*
   * Method that fires when a team is selected
   */
  onTeamSelect() {
    this.disableOrders();
    this.httpClient.get<ApiResponse<LicenseOrder[]>>(`${this.licenseOrderUrl}?team_id=${this.transferLicenseForm.value.teamId}`).subscribe(result => {
      this.licenseOrders.push(this.newOrder);
      this.licenseOrders = this.licenseOrders.concat(result.body);
      this.newOrderFormEnabled = this.transferLicenseForm.value.orderId == -1;
    });
  }
  /*
   * Empties the license order array and hides the new order form boxes
   */
  disableOrders() {
    this.licenseOrders = [];
    this.newOrderFormEnabled = false;
  }
  /*
   * Method that fires when an order is selected
   * -1 is the value for creating a new order
   */
  onOrderSelect() {
    this.newOrderFormEnabled = this.transferLicenseForm.value.orderId == -1;
  }
  /*
   * Method called when the transfer license button is clicked
   */
  onTransferClick() {
    if (this.transferLicenseForm.value.orderId == -1) {
      if (this.transferLicenseForm.value.orderNameField == null || this.transferLicenseForm.value.orderNameField == "") {
        SnackBarHelper.sendSnackbar(this.snackBar, "Order Name is required", "failure-snackbar");
      } else if (this.transferLicenseForm.value.orderDescriptionField == null || this.transferLicenseForm.value.orderDescriptionField == "") {
        SnackBarHelper.sendSnackbar(this.snackBar, "Order Description is required", "failure-snackbar");
      } else if (this.transferLicenseForm.value.orderReferenceNumberField == null || this.transferLicenseForm.value.orderReferenceNumberField == "") {
        SnackBarHelper.sendSnackbar(this.snackBar, "Order Reference Number is required", "failure-snackbar");
      } else {
        this.createOrderAndTransferLicense();
      }
    } else {
      this.transferLicense();
    }
  }
  /**
   * Creates a new license order, then transfers the license to the newly created order
   */
  createOrderAndTransferLicense() {
    this.formSubmitting = true;

    // Depth of 1 for License GetByGuid is equivalent to LicenseIncludeOptions.IncludeAll
    this.httpClient.get<ApiResponse<License>>(`${this.licenseUrl}/${this.licenseGuid}?guid=${this.licenseGuid}&depth=1`).subscribe(response => {
      if (response.success) {
        var modules: string[] = [];
        var expiration: Date = new Date(response.body.expirationDate);
        response.body.modules.forEach(module => {
          modules.push(module.guid);
        });
        const request: LicenseOrderCreateRequest = {
          referenceNumber: this.transferLicenseForm.value.orderReferenceNumberField,
          companyId: undefined,
          name: this.transferLicenseForm.value.orderNameField,
          description: this.transferLicenseForm.value.orderDescriptionField,
          expirationDate: expiration,
          quantity: 0,
          modulePresetId: undefined,
          teamId: this.transferLicenseForm.value.teamId,
          moduleGuids: modules
        };
        this.httpClient.post<ApiResponse<LicenseOrderCreateResponse>>(`${this.licenseOrderUrl}`, request).subscribe(response => {
          if (response.success) {
            this.transferLicense(response.body.id);
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
            this.formSubmitting = false;
          }
        });
      } else {
        SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        this.formSubmitting = false;
      }
    });
  }
  /**
   * Transfers a license to a new license order
   * @param orderId The id of license order that the license is being transferred to
   */
  transferLicense(orderId: number = undefined) {
    this.formSubmitting = true;
    const request: TransferLicenseRequest = {
      LicenseGuid: this.licenseGuid,
      OrderId: orderId ?? this.transferLicenseForm.value.orderId
    };
    this.httpClient.post<ApiResponse<boolean>>(`${this.licenseUrl}/transfer`, request).subscribe(response => {
      if (response.success) {
        SnackBarHelper.sendSnackbar(this.snackBar, "License successfully transferred", "success-snackbar");
        // Navigate to the license order page after transfer
        this.router.navigate(['/licenseOrders', request.OrderId]);
      } else {
        SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        this.formSubmitting = false;
      }
    });
  }
}