<a mat-dialog-title>Add Additional Claim</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<mat-dialog-content>
    <form [formGroup]="addClaimForm">
        <fieldset>
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Key</mat-label>
                    <input matInput type="string" formControlName="key" minlength="1" required />
                </mat-form-field>
            </section>
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Value</mat-label>
                    <input matInput type="string" formControlName="value" minlength="1" required />
                </mat-form-field>
            </section>
            <section style="justify-content: flex-end;">
                <mat-checkbox (change)="onChange($event)" [disabled]="!data.AllowEncryption" color="primary" labelPosition="before">Encrypt Value</mat-checkbox>
            </section>
            <section *ngIf="data.AllowEncryption && !data.AllPublicKeysSet && encrypted" style="justify-content: flex-end">
                <mat-error>Claim will not be added to all licenses</mat-error>
            </section>
        </fieldset>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" (click)="addAdditionalClaim()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>