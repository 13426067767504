import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiPaths } from 'src/app/enums/api-paths';
import { Team } from 'src/app/teams/interfaces/teams.interface';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LicenseOrder } from '../interfaces/license-order.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadService } from 'src/app/download-service';
import { PaginatedApiResponse } from 'src/app/common/Interfaces/paginated-api-repsonse.interface';
import { PaginatorObject } from 'src/app/common/Interfaces/paginator-object';
import { PageEvent } from '@angular/material/paginator';
import { TimeHelpers } from 'src/app/common/Helpers/time-helper';
import { Module } from 'src/app/modules/interfaces/module.interface';
import { License } from 'src/app/licenses/interfaces/license.interface';
import { MatDialog } from '@angular/material/dialog';
import { ExtendExpirationComponent } from '../edit-license-order-dialogs/extend-expiration/extend-expiration.component';
import { DisableLicenseOrderComponent } from '../edit-license-order-dialogs/disable-license-order/disable-license-order.component';
import { AddLicensesComponent } from '../edit-license-order-dialogs/add-licenses/add-licenses.component';
import { LicenseHelper } from 'src/app/common/Helpers/license-helper';
import { ApiVersions } from 'src/app/enums/api-versions';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { EditModulesComponent } from '../edit-license-order-dialogs/edit-modules/edit-modules.component';

@Component({
  selector: 'app-view-license-order',
  templateUrl: './view-license-order.component.html',
  styleUrls: ['./view-license-order.component.css']
})
/**
 * Component used view company information
 */
export class ViewLicenseOrderComponent implements OnInit {

  private httpClient: HttpClient;
  private teamUrl: string;
  private licenseUrl: string;
  private licenseOrderUrl: string;
  private moduleUrl: string;

  public licenseOrder: LicenseOrder = {
    id: 0,
    referenceNumber: "",
    name: "",
    description: "",
    modulePreset: {
      id: 0,
      name: "",
      description: "",
      moduleGuids: []
    },
    expirationDate: "01/01/1000",
    team: {
      id: 0,
      name: "",
      description: "",
      company: {
        name: '',
        id: 0
      }
    },
    historySetId: 0,
    licenses: []
  };

  public daysUntilExpiration = 0;

  public reloadHistoryFlag: boolean = false;
  public licensesLoaded = false;
  public licenses: License[];
  public licensesDataSource: MatTableDataSource<License> = new MatTableDataSource();
  public licensePaginatorObject: PaginatorObject = new PaginatorObject();
  public licenseColumns: string[] = ['status','key', 'activated', 'mode', 'checkIn', 'export'];

  public modulesLoaded = false;
  public modules: Module[];
  public modulesDataSource: MatTableDataSource<Module> = new MatTableDataSource();
  public modulePaginatorObject: PaginatorObject = new PaginatorObject();
  public moduleColumns: string[] = ['application', 'name'];

  public team: Team = {
    name: "",
    id: 0,
    description: "",
    company: {
      name: '',
      id: 0,
    }
  };

  public activated: number = 0;
  //#endregion

  //#region constructor
  constructor(http: HttpClient, private route: ActivatedRoute, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private dialog: MatDialog) {
    this.httpClient = http;
    this.licenseOrderUrl = `${ApiHelper.GetUrl(ApiPaths.LicenseOrder)}/`;
    this.licenseUrl = ApiHelper.GetUrl(ApiPaths.License);
    this.teamUrl = `${ApiHelper.GetUrl(ApiPaths.Team)}/`;
    this.moduleUrl = ApiHelper.GetUrl(ApiPaths.Module);

    this.matIconRegistry.addSvgIcon(
      `uid_export`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/export.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `uid_add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/add.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `uid_edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/edit.svg")
    );

    this.licensePaginatorObject.pageSize = environment.shortPage;
    this.modulePaginatorObject.pageSize = environment.shortPage;
  }
  //#endregion

  //#region OnInit
  ngOnInit() {
    this.loadLicenseOrder().add(() => {
      this.loadTeamInformation();
    })
    this.loadLicenses();
    this.loadModules();
  }
  //#endregion

  //#region formatters
  /**
     * formats expiration date
     * @param date expiration date
     * @returns formatted expiration date
     */
  ExpirationDateString(date: string) {
    return TimeHelpers.formatDate(date)
  }
  /**
   * Formats activated on string
   * @param date activation date
   * @returns formatted date
   */
  ActivationDateString(date: string) {
    return TimeHelpers.formatDate(date)
  }
  /**
   * Formats LastCheckIn string
   * @param date late check in date
   * @returns formatted date
   */
  LastCheckInString(date: string) {
    return TimeHelpers.formatWithTime(date);
  }
  //#endregion

  //#region events
  /**
   * Download all keys in license order
   */
  exportKeys() {
    let download_request = `${this.licenseOrderUrl}downloadKeys?id=${this.route.snapshot.params.id}`;
    let download_service = new DownloadService(this.httpClient);
    download_service.download(download_request);
  }
  /**
   * Update licenses list based on new page index
   * @param event event sent on page change
   */
  licensePageChanged(event: PageEvent) {
    this.licensePaginatorObject.onPageEvent(event);
    this.loadLicenses();
  }

  /**
   * Update modules list based on new page index
   * @param event event sent on page change
   */
  modulePageChanged(event: PageEvent) {
    this.modulePaginatorObject.onPageEvent(event);
    this.loadModules();
  }

  /**
   * Launches extend license dialog
   * @param event button event
   */
  extendLicense(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(ExtendExpirationComponent, {
      data: {
        licenseOrderId: this.licenseOrder.id,
        currentExpirationDate: new Date(this.licenseOrder.expirationDate),
      },
      autoFocus: false,
      panelClass: "view-panel"
    })
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadLicenseOrder();
      this.reloadHistoryItems();
    })
  }
  /**
   * Launches add licenses dialog
   * @param event button event
   */
  addLicenses(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(AddLicensesComponent, {
      data: {
        licenseOrderId: this.licenseOrder.id,
        currentExpirationDate: new Date(this.licenseOrder.expirationDate),
      },
      autoFocus: false,
      panelClass: "view-panel"
    })
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadLicenses();
      this.reloadHistoryItems();
    })
  }

  /**
   * Launches edit modules dialog
   * @param event button event
   */
   editModules(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(EditModulesComponent, {
      data: {
        licenseOrderId: this.licenseOrder.id,
      },
      autoFocus: false,
      panelClass: "view-panel"
    })
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadModules();
      this.reloadHistoryItems();
    })
  }

  /**
   * Launch disable license dialog
   * @param event button event
   */
  disableLicense(event) {
    event.preventDefault();

    let dialogRef = this.dialog.open(DisableLicenseOrderComponent, {
      data: {
        licenseOrderId: this.licenseOrder.id
      },
      autoFocus: false,
      panelClass: "view-panel"
    })
    dialogRef.afterClosed().subscribe().add(() => {
      this.loadLicenseOrder();
      this.reloadHistoryItems();
    })
  }
  //#endregion

  //#region helpers
  /**
   * Loads license order from database
   * @returns observable licenses order
   */
  loadLicenseOrder() {
    this.activated = 0;
    return this.httpClient.get<ApiResponse<LicenseOrder>>(`${this.licenseOrderUrl}${this.route.snapshot.params.id}?depth=1`).subscribe({
      next: (result) => {
        this.licenseOrder = result.body;
        this.licenseOrder.licenses.forEach((lic) => { if (lic.activated) {
          this.activated += 1;
        }})
        if (result.body.expirationDate != null)
          this.daysUntilExpiration = TimeHelpers.daysUntil(new Date(Date.now()), new Date(result.body.expirationDate))
      },
      error: (e) => console.log(e)
    })
  }

  /**
   * Gets team name and company from database
   */
  loadTeamInformation() {
    this.httpClient.get<ApiResponse<Team>>(`${this.teamUrl}${this.licenseOrder.team.id}?depth=1`).subscribe(result => {
      this.team = result.body;
    },
      error => console.error(error));
  }

  /**
 * Loads new license list from database 
 * @param page the page the user has requested
 */
  loadLicenses() {
    let paged_url = `${this.licenseUrl}?${this.licensePaginatorObject.pageQuery}&license_order_id=${this.route.snapshot.params.id}`;
    this.licensesLoaded = false;

    this.httpClient.get<PaginatedApiResponse<License[]>>(paged_url).subscribe(result => {
      this.licenses = result.body;
      this.licensePaginatorObject.onPaginatedResponse(result);
      this.licensesDataSource.data = result.body;
    },
      error => console.error(error));
    this.licensesLoaded = true;
  }

  /**
   * Loads new module list from database 
   * @param page the page the user has requested
   */
  loadModules() {
    let paged_url = `${this.moduleUrl}?${this.modulePaginatorObject.pageQuery}&license_order_id=${this.route.snapshot.params.id}&depth=0`;
    this.modulesLoaded = false;

    this.httpClient.get<PaginatedApiResponse<Module[]>>(paged_url).subscribe(result => {
      this.modules = result.body;
      this.modulePaginatorObject.onPaginatedResponse(result);
      this.modulesDataSource.data = result.body;
    },
      error => console.error(error));
    this.modulesLoaded = true;
  }
 /**
  * Gets color for license status
  * @param license license to check
  * @returns license color
  */
  getStatusIconColor(license: License){
    return LicenseHelper.getStatusIcon(license)[1]
  }
 /**
  * Gets icon for license status
  * @param license license to check
  * @returns color to use directly in css
  */
  getStatusIcon(license: License){
    return LicenseHelper.getStatusIcon(license)[0];
  }
  /**
   * Determines if the public key is valid
   * @param license The license to check
   * @returns True if the key is valid or the license is not activated, false otherwise
   */
  validPublicKey(license: License) {
      return LicenseHelper.validPublicKey(license) || !license.activated;
  }
  /**
   * Flips a boolean flag which causes the child component OnChanges to fire
   */
  reloadHistoryItems() {
    this.reloadHistoryFlag = !this.reloadHistoryFlag;
  }
}
