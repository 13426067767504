import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiPaths } from 'src/app/enums/api-paths';
import { CompanyCreateRequest } from '../Interfaces/companyCreateRequest.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { CreateResponse } from 'src/app/common/Interfaces/create-response.interface';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
/**
 * Component used to add a new company to the system
 */
export class AddCompanyComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  addCompanyForm: FormGroup;
  lastAddedCompanyName: string;

  formSubmitting: boolean = false;

  constructor(http: HttpClient, private snackBar: MatSnackBar) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.Company);
  }

  ngOnInit() {
    this.addCompanyForm = new FormGroup({
      name: new FormControl('', [Validators.required])
    });
  }

  /**
   * Validates and attemps to add the company to the database
   */
  OnSubmit() {
    if (this.addCompanyForm.valid) {
      console.debug(`Form Submitted.. Name ${this.addCompanyForm.value.name}`);
      this.formSubmitting = true;

      const company_to_add: CompanyCreateRequest = {
        name: this.addCompanyForm.value.name
      };
      
      this.httpClient.post<ApiResponse<CreateResponse>>(this.apiUrl, company_to_add).subscribe(result => {
        this.lastAddedCompanyName = company_to_add.name;
        if (result.body != null) {
          SnackBarHelper.sendSnackbar(this.snackBar, "Company Successfully Added!", "success-snackbar");
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, "Error Adding Company!", "failure-snackbar");
        }

      }).add(() => {
        this.formSubmitting = false;
        FormHelpers.resetForm(this.addCompanyForm)

      })
    }
    else {
      SnackBarHelper.sendSnackbar(this.snackBar, "Error Adding Company!", "failure-snackbar");
      this.formSubmitting = false;
    }
  }

  /**
   * Gets the controls that are part of the add company form for use in html
   * @returns: the controls that are part of the add company form
   */
  get addCompanyFormControls() {
    return this.addCompanyForm.controls;
  }
}
