import { Component, OnInit, Inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { DeleteClaimRequest } from '../../interfaces/delete-claim-request';
import { DeleteOrderClaimRequest } from '../../interfaces/delete-order-claim-request';
import { DeleteLicenseClaimRequest } from '../../interfaces/delete-license-claim-request';

@Component({
  selector: 'app-delete-additional-claim',
  templateUrl: './delete-additional-claim.component.html',
  styleUrls: ['./delete-additional-claim.component.css']
})
export class DeleteAdditionalClaimComponent implements OnInit {

  httpClient: HttpClient;
  apiUrl: string;
  formSubmitting: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteClaimRequest, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = `${ApiHelper.GetUrl(ApiPaths.License)}/${ApiPaths.AdditionalClaim}`;
  }

  ngOnInit(): void {
  }

  /*
   * Decides whether the request should be routed to the license or license order delete endpoints
   */
  deleteAdditionalClaim() {
    if (this.data.ClaimLicenseGuid != null && this.data.ClaimOrderId == null) {
      this.deleteLicenseClaim();
    } else if (this.data.ClaimLicenseGuid == null && this.data.ClaimOrderId != null) {
      this.deleteOrderClaim();
    } else {
      SnackBarHelper.sendSnackbar(this.snackBar, "A valid license order id or license guid is required to delete a claim", "failure-snackbar");
    }
  }
  /*
   * Deletes an additional claim associated with a license order
   */
  deleteOrderClaim() {
    const request: DeleteOrderClaimRequest = {
      LicenseOrderId: this.data.ClaimOrderId,
      Key: this.data.Key
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/deleteLicenseOrderClaim`, request).subscribe({
      next: (response) => {
        if (response.body) {
          SnackBarHelper.sendSnackbar(this.snackBar, "Additional claim successfully deleted", "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, "Failed to delete additional claim", "failure-snackbar");
        }
      }
    }).add(() => {
      this.formSubmitting = false;
    })
  }
  /*
   * Deletes an additional claim associated with a license
   */
  deleteLicenseClaim() {
    const request: DeleteLicenseClaimRequest = {
      LicenseGuid: this.data.ClaimLicenseGuid,
      Key: this.data.Key
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/deleteLicenseClaim`, request).subscribe({
      next: (response) => {
        if (response.body) {
          SnackBarHelper.sendSnackbar(this.snackBar, "Additional claim successfully deleted", "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, "Failed to delete additional claim", "failure-snackbar");
        }
      }
    }).add(() => {
      this.formSubmitting = false;
    })
  }
}
