<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">Add User</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle">Add a user to the system</a>
</mat-toolbar-row>
<mat-divider></mat-divider>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="addUserForm" (ngSubmit)="OnSubmit()" novalidate>
    <fieldset [disabled]="formSubmitting">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username" required>
                <mat-error>Invalid username</mat-error>
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
        </section>
        <section>
        <mat-form-field appearance="outline">
            <mat-label>Display Name</mat-label>
            <input matInput formControlName="displayName" required>
        </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
        </section>
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" minlength="8" required>
                <mat-hint>Min length: 8</mat-hint>
                <mat-error>Invalid Password</mat-error>
            </mat-form-field>
        </section>
        <section>
            <button mat-raised-button class="submit-button" type="submit"
                color="primary" disableRipple> Add </button>
        </section>
    </fieldset>
</form>