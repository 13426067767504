import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  toggled = false;

  onToggle(event: boolean) {
    this.toggled = event
  }
}
