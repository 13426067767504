/**
 * Various API Endpoints
 */
export enum ApiPaths{
    Company = 'company',
    License = 'license',
    LicenseOrder = 'licenseOrder',
    Team = 'team',
    User = 'users',
    Login = 'login',
    ModulePreset = 'modulePreset',
    Application = 'application',
    Module = 'module',
    UpdateTrack = 'updateTrack',
    Statistic = 'statistics',
    AdditionalClaim = 'additional-claims',
    History = 'history'
}