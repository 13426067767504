import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ApiHelper } from '../common/Helpers/api-helper';
import { ApiPaths } from '../enums/api-paths';
import { ApiResponse } from '../common/Interfaces/api-response.interface';
import { GetLicenseStatisticsResponse } from './interfaces/get-license-statistics-response';
import { MatTableDataSource } from '@angular/material/table';
import { ProductInfo } from './interfaces/product-info';
import { ActiveVersion } from './interfaces/active-versions';
import { StringHelper } from '../common/Helpers/string-helper';
import { GetRecentActivityResponse } from './interfaces/get-recent-activity-response';
import { RecentActivity } from './interfaces/recent-activity';
import { TimeHelpers } from '../common/Helpers/time-helper';
import { GetActiveVersionsResponse } from './interfaces/get-active-versions-response';
import { MatDialog } from '@angular/material/dialog';
import { ActiveVersionDetailsComponent } from './active-version-details/active-version-details.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Summary } from './interfaces/summary';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  private httpClient: HttpClient;
  private apiUrl: string;

  public productsLoaded: boolean;

  public numActivated: number;
  public checkedIn: number;

  // Products table
  public productsDataSource: MatTableDataSource<ProductInfo> = new MatTableDataSource();
  public productsColumns: string[] = ['product', 'total', 'recent', 'release'];
  public selectedProduct: ProductInfo;

  // Recent Activity
  public recentActivityDataSource: MatTableDataSource<RecentActivity> = new MatTableDataSource();
  public recentActivityColumns: string[] = ['company', 'action', 'product', 'version', 'lastCheckIn', 'license', 'info'];
  public recentActivityLoaded: boolean;

  // Active Versions
  public releasedProductsList: ProductInfo[];
  private releaseProductsVersions: [];
  public activeVersionsDataSource: MatTableDataSource<ActiveVersion> = new MatTableDataSource();
  public activeVersionsColumns: string[] = ['active-version', 'track', 'count', 'summary'];
  public activeVersionsLoaded: boolean;

  constructor(http: HttpClient, private dialog: MatDialog, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `uid-summary`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/summary.svg")
    );
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.Statistic);
  }

  ngOnInit() {
    this.numActivated = 0;
    this.checkedIn = 0;
    this.productsLoaded = false;
    this.recentActivityLoaded = false;
    this.activeVersionsDataSource.data = [<ActiveVersion>{ version: '-', track: '-' }];
    this.activeVersionsLoaded = true;

     this.loadOverview().add(() => {
       this.productsLoaded = true;

       this.loadActiveVersions();
       this.activeVersionsLoaded = true;
    });
    this.loadRecentActivity().add(() => {
      this.recentActivityLoaded = true;
    });
  }

  /** Loads statistics overview from database  */
  loadOverview() {
    let url = `${this.apiUrl}/overview`;

    return this.httpClient.get<ApiResponse<GetLicenseStatisticsResponse>>(url).subscribe({
      next: (result) => {
        result.body.statistics.forEach(x => {
          this.numActivated += x.totalActivated;
          this.checkedIn += x.recentlyCheckedIn;
        });
        this.productsDataSource.data = result.body.statistics.map(x => <ProductInfo>{
          name: x.appName,
          recentlyCheckedIn: x.recentlyCheckedIn,
          totalAvailable: x.totalActivated,
          release: StringHelper.isNullOrEmpty(x.latestRelease) ? '-' : x.latestRelease,
          id: x.appId,
        });
      },
      error: (e) => console.log(e)
    });
  }

  /** Loads recent activity from database  */
  loadRecentActivity() {
    let url = `${this.apiUrl}/recent-activity`;
    return this.httpClient.get<ApiResponse<GetRecentActivityResponse>>(url).subscribe({
      next: (result) => {
        this.recentActivityDataSource.data = result.body.recentActivity.map(x => <RecentActivity>{
          license: x.license,
          product: x.product,
          lastCheckIn: TimeHelpers.formatWithTime(x.lastCheckIn),
          action: x.action,
          company: x.company,
          version: StringHelper.isNullOrEmpty(x.version) ? '-' : x.version
        });
      },
      error: (e) => console.log(e)
    });
  }

  /** Loads active versionsfrom database and add to list if active versions exist */
  loadActiveVersions() {
    this.activeVersionsLoaded = false;
    this.releasedProductsList = [];
    this.releaseProductsVersions = [];

    this.productsDataSource.data.forEach(x => {
      let url = `${this.apiUrl}/${x.id}`;
      this.httpClient.get<ApiResponse<GetActiveVersionsResponse>>(url).subscribe({
        next: (result) => {
           if (result.body.activeVersions.length > 0) {
             this.releaseProductsVersions[x.id] = result.body.activeVersions;
             this.releasedProductsList.push(x);
           }
        },
        error: (e) => {
          console.log(e)
        }
      });
    });
  }

  /**
   * Switches list of active versions to correspond with selected product
   * @param event event containing product id to display
   */
  switchSelection(event) {
    this.selectedProduct = event.value;
    if (event != null && event.value != null && event.value.id != null)
      this.activeVersionsDataSource.data = this.releaseProductsVersions[event.value.id];
  }

  /**
   * Opens modal with active version details
   * @param row active version to display details for
   */
  showInfo(row: ActiveVersion) {
     if (row != null && row.details != null && row.details.length > 0) {
       this.dialog.open(ActiveVersionDetailsComponent, {
         data: <Summary>{
          productInfo: this.selectedProduct,
          activeVersion: row,
         },
         autoFocus: false,
         panelClass: "view-panel-large"
       })
     }
  }
}
