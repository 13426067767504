<mat-toolbar class="section-header">
    <a class="section-header-title">{{company.name}} Information</a>
</mat-toolbar>

<mat-divider></mat-divider>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Teams</a>
        <span class="spacer"></span>
        <button mat-raised-button class="add-button" [routerLink]="['/teams/add']">Add Team</button>
    </div>

    <app-uid-spinner [visible]="!teamsLoaded"></app-uid-spinner>

    <mat-table [dataSource]="teamsDataSource" *ngIf="teamsLoaded && hasTeams">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="teamsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: teamsColumns;">
        </mat-row>
    </mat-table>

    <a class="warning-text" style="display: flex; justify-content: center;" *ngIf="!hasTeams">Company has no teams!</a>
</mat-card>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">License Orders</a>
    </div>
    <app-uid-spinner [visible]="!licenseOrdersLoaded"></app-uid-spinner>

    <mat-table [dataSource]="licenseOrdersDataSource" *ngIf="licenseOrdersLoaded">
        <ng-container matColumnDef="referenceNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Reference Number </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.referenceNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Order Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="modulePreset">
            <!-- module preset name and license quantity added in LIC-132 -->
            <mat-header-cell *matHeaderCellDef mat-sort-header> Module Preset </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.modulePreset.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Number of Licenses </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.licenses.length}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Expiration Date </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{formatDate(row.expirationDate)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" [routerLink]="['/licenseOrders/',row.id]" disableRipple>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="licenseOrderColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: licenseOrderColumns;">
        </mat-row>
    </mat-table>

    <mat-paginator [pageIndex]="licenseOrderPaginatorObject.pageIndex" [pageSize]="licenseOrderPaginatorObject.pageSize"
        [length]="licenseOrderPaginatorObject.totalRecords" (page)="pageChanged($event)" aria-label="Select page"
        hidePageSize>
    </mat-paginator>
</mat-card>