<mat-toolbar class="section-header">
    <a class="section-header-title">{{user.displayName}} Information</a>
    <span class="spacer"></span>
    <button mat-icon-button disableRipple [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['./edit']" color="primary">
        <mat-icon>edit</mat-icon>
    </button>
</mat-toolbar>

<mat-divider></mat-divider>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Details</a>
    </div>
    <div class="flex">
    <mat-card-content class="stats-card-content">
        <a class="bolded">Username</a>
        <a class="bordered">{{user.username}}</a>
    </mat-card-content>
    <mat-card-content class="stats-card-content">
        <a class="bolded">Email</a>
        <a class="bordered">{{user.emailAddress? user.emailAddress : "-"}}</a>
    </mat-card-content>
</div>
</mat-card>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Teams</a>
    </div>

    <app-uid-spinner [visible]="!teamsLoaded"></app-uid-spinner>

    <mat-table [dataSource]="teamsDataSource" *ngIf="teamsLoaded && hasTeams">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Company </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.company.name}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="teamsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: teamsColumns;">
        </mat-row>
    </mat-table>

    <a class="warning-text" style="display: flex; justify-content: center;" *ngIf="!hasTeams">User is not in any teams!</a>
</mat-card>