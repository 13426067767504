import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.css']
})
/**
 * Spinner that grays out the screen
 */
export class ModalSpinnerComponent {
  /** Spinner visibility */
  @Input() visible = true;
  /** Optional spinner message */
  @Input() message = null;
}
