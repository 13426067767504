<mat-toolbar class="section-header">
    <a class="section-header-title">License Orders</a>
    <button mat-raised-button class="add-button section-header-button" [routerLink]="['/licenses/order']" disableRipple>New Order</button>
    <button mat-raised-button color="primary" class="section-header-button" [routerLink]="['/licenses/offlineactivation']" disableRipple>License Offline
        Activation</button>
</mat-toolbar>

<app-uid-spinner [visible]="!loaded"></app-uid-spinner>

<div *ngIf="loaded">
    <mat-table [dataSource]="licenseOrderDataSource">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="referenceNumber">
            <mat-header-cell *matHeaderCellDef> Reference Number </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.referenceNumber}} </mat-cell>
        </ng-container>
        <!-- Functionality added in LIC-25 -->
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" [routerLink]="['./',row.id]" disableRipple>
                    <mat-icon>search</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;">
        </mat-row>
    </mat-table>

    <mat-paginator [pageIndex]="paginatorObject.pageIndex" [pageSize]="paginatorObject.pageSize"
        [length]="paginatorObject.totalRecords" (page)="pageChanged($event)" aria-label="Select page" hidePageSize>
    </mat-paginator>
</div>