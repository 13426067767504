<a mat-dialog-title>Extend Expiration Date</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>
<mat-dialog-content>
    <form [formGroup]="extendLicenseForm" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="formSubmitting">
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Expiration Date</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="minDate"  formControlName="expirationDate" required>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            </section>
        </fieldset>
    </form>
</mat-dialog-content>

<mat-dialog-actions>    
    <button mat-flat-button color="primary" class="dialog-button" (click)="extendLicense()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>