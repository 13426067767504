<a mat-dialog-title>Set Update Track</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>
<mat-dialog-content>
    <form [formGroup]="setUpdateTrackForm" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="formSubmitting">
            <section>
                <mat-form-field>
                    <mat-label>Update Track</mat-label>
                    <mat-select formControlName="updateTrackSelect">
                        <mat-option *ngFor="let track of updateTracks" [value]="track">
                            {{track.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </section>
        </fieldset>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" (click)="updateTrack()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>