<a mat-dialog-title>Edit Modules</a>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<mat-dialog-content>
    <app-uid-spinner [visible]="!appsLoaded"></app-uid-spinner>
    <div *ngIf="appsLoaded">
        <mat-expansion-panel color="primary" *ngFor="let app of applications" [expanded]="step == app.guid"
            (opened)="setStep(app.guid)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{app.description}}</mat-panel-title>
                <mat-panel-description style="justify-content: end; flex: initial;">
                    {{applicationModuleCount[app.guid]}}/{{app.modules.length}} selected
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-checkbox *ngFor="let module of app.modules" color="primary" [value]="module.guid"
                (change)="selectModule($event)" [checked]="isSelected(module.guid)" style="width: 100%;"
                [disabled]="formSubmitting">
                <a class="bolded" >{{module.name}}</a><a>{{module.description != null && module.description !=
                "" ? ": "
                : ""}}{{module.description}}
            </a>
            </mat-checkbox>
        </mat-expansion-panel>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" (click)="editModules()">Confirm</button>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>