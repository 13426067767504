import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { LoginService } from 'src/app/common/Helpers/login/login.service';
import { NavigationService } from 'src/app/common/Helpers/services/navigation.service';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { UserUpdateRequest } from '../interfaces/user-update-request';
import { User } from '../interfaces/user.interface';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {

  httpClient: HttpClient
  userUrl: string;

  editUserForm: FormGroup;
  formSubmitting: boolean = false;

  private subscription = null;
  private policyHolderId = 0;

  public user: User = {
    id: 0,
    username: '',
    displayName: '',
    emailAddress: ''
  }


  constructor(http: HttpClient, private route: ActivatedRoute, service: LoginService, public snackBar: MatSnackBar, private navigation: NavigationService) {
    this.httpClient = http;
    this.userUrl = ApiHelper.GetUrl(ApiPaths.User);
    this.subscription = service.observeUser.subscribe({ next: (user) => this.policyHolderId = user.id });
  }

  ngOnInit() {
    this.editUserForm = new FormGroup({
      displayName: new FormControl(this.user.displayName),
      email: new FormControl(this.user.emailAddress, Validators.email),
    });
    this.loadUserInformation().add(() => {
      this.editUserForm.controls.displayName.setValue(this.user.displayName);
      this.editUserForm.controls.email.setValue(this.user.emailAddress)
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Loads user information from the database
   */
  loadUserInformation() {
    return this.httpClient.get<ApiResponse<User>>(`${this.userUrl}/${this.route.snapshot.params.id}?depth=2`).subscribe({
      next: (result) => {
        this.user = result.body;
      },
      error: (error) => console.error(error)
    });
  }
  /**
   * Updates user if form is valid
   */
  OnSubmit() {
    if (this.editUserForm.valid) {
      this.formSubmitting = true;

      const request: UserUpdateRequest = {
        userToUpdateId: this.user.id,
        displayName: this.editUserForm.value.displayName,
        emailAddress: this.editUserForm.value.email,
        policyHolderId: this.policyHolderId
      }

      this.httpClient.post<ApiResponse<boolean>>(`${this.userUrl}/updateUser`, request).subscribe({
        next: (response) => {
          if (response.body) {
            SnackBarHelper.sendSnackbar(this.snackBar, "User successfully updated!", "success-snackbar");
            this.navigation.back();
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, "User failed to update", "failuer-snackbar");
          }
        },
        error: () => { SnackBarHelper.sendSnackbar(this.snackBar, "User failed to update", "failure-snackbar"); }
      }).add(() => {
        this.formSubmitting = false;
      });
    }
  }
}
