<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Reset Password</a>
    </div>
<form [formGroup]="passwordForm" (ngSubmit)="OnSubmit()">
    <section>
        <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="newPassword" type="password" autocomplete="new-password" minlength="8" required />
        </mat-form-field>
    </section>
    <section>
        <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="repeatPassword" type="password" autocomplete="new-password" minlength="8" required />
            <mat-error *ngIf="passwordForm.hasError('match')">Passwords do not match</mat-error>
        </mat-form-field>
    </section>
    <section>
        <button mat-raised-button class="submit-button" type="submit" color="primary" disableRipple>Reset</button>
    </section>
</form>
</mat-card>