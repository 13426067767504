<a mat-dialog-title>History Item - {{data.id}}</a>
<mat-dialog-content>
    <div style="height:fit-content; max-height:80vh; width:fit-content; max-width: 80vw">
        <mat-card-content>
            <pre>{{historyItem}}</pre>
        </mat-card-content>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="dialog-button" mat-dialog-close>Close</button>
</mat-dialog-actions>