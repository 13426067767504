<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">Add Company</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle">Add a company to the system</a>
</mat-toolbar-row>
<mat-divider></mat-divider>
<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="addCompanyForm" (ngSubmit)="OnSubmit()" novalidate>
    <fieldset [disabled]="formSubmitting">
        <section>
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name" required>
            </mat-form-field>
        </section>
        <section>
            <button mat-raised-button class="submit-button" color="primary" type="submit" disableRipple> Add </button>
        </section>
    </fieldset>
</form>