import { ModuleWithProviders } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AuthGuard } from "./common/Helpers/login/auth.guard";
import { AddCompanyComponent } from "./companies/add-company/add-company.component";
import { CompaniesComponent } from "./companies/companies.component";
import { ViewCompanyComponent } from "./companies/view-company/view-company.component";
import { HomeComponent } from "./home/home.component";
import { CreateLicenseOrderComponent } from "./license-orders/create-license-order/create-license-order.component";
import { LicenseOrdersComponent } from "./license-orders/license-orders.component";
import { ViewLicenseOrderComponent } from "./license-orders/view-license-order/view-license-order.component";
import { LicensesComponent } from "./licenses/licenses.component";
import { OfflineActivationComponent } from "./licenses/offline-activation/offline-activation.component";
import { ViewLicenseComponent } from "./licenses/view-license/view-license.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { CreateTeamComponent } from "./teams/create-team/create-team.component";
import { AddUserComponent } from "./users/add-user/add-user.component";
import { EditUserComponent } from "./users/edit-user/edit-user.component";
import { UsersComponent } from "./users/users.component";
import { ViewUserComponent } from "./users/view-user/view-user.component";
import { TransferLicenseComponent } from "./licenses/transfer-license/transfer-license.component";

/** List of routes for Side Nav */
export const shortcutRoutes = {
    Home: "./",
    Orders: "./licenseOrders",
    Licenses: "./licenses",
    LicenseActivation: "./licenses/offlineactivation",
    Companies: "./companies",
    Users: "./users",
};

export const appRoutes: Route[] = [
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [ AuthGuard ]},
    { path: 'login', component: LoginComponent, pathMatch: "full"},
    { path: 'companies', component: CompaniesComponent, canActivate: [ AuthGuard ] },
    { path: 'companies/add', component: AddCompanyComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
    { path: 'licenses', component: LicensesComponent, canActivate: [ AuthGuard ]},
    { path: 'licenses/offlineactivation', component: OfflineActivationComponent, canActivate: [ AuthGuard ]},
    { path: 'licenses/order', component: CreateLicenseOrderComponent, canActivate: [ AuthGuard ]},
    { path: 'licenses/:id', component: ViewLicenseComponent, canActivate: [ AuthGuard ]},
    { path: 'licenses/transfer/:id', component: TransferLicenseComponent, canActivate: [ AuthGuard ]},
    { path: 'companies/:id', component: ViewCompanyComponent, canActivate: [ AuthGuard ]},
    { path: 'users', component: UsersComponent, canActivate: [ AuthGuard ]},
    { path: 'licenseOrders', component: LicenseOrdersComponent, canActivate: [ AuthGuard ]},
    { path: 'teams/add', component: CreateTeamComponent, canActivate: [ AuthGuard ]},
    { path: 'licenseOrders/:id', component: ViewLicenseOrderComponent, canActivate: [ AuthGuard ]},
    { path: 'users/add', component: AddUserComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
    { path: 'users/:id', component: ViewUserComponent, canActivate: [ AuthGuard ]},
    { path: 'users/:id/edit', component: EditUserComponent, canActivate: [ AuthGuard ]},
    { path: 'resetPassword', component: ResetPasswordComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
  ]

export const appRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);

/**
 * Class that holds all app routes
 */
export class AppRoutes {
    
}
