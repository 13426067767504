<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">Offline Activation</a>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle">If a pc is offline use this screen to activate the license</a>
</mat-toolbar-row>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle"><a class="bolded">Customer Provides:</a> Activation code generated by application on local
        pc</a>
</mat-toolbar-row>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle"><a class="bolded">UID Provides:</a> License File via Email to Customer</a>
</mat-toolbar-row>
<mat-divider></mat-divider>

<app-modal-spinner [visible]="formSubmitting"></app-modal-spinner>

<form [formGroup]="offlineActivationForm" (ngSubmit)="OnSubmit()" novalidate>
    <fieldset [disabled]="formSubmitting">
    <section>
        <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Activation Code</mat-label>
            <textarea matInput formControlName="activationCode"></textarea>
        </mat-form-field>
    </section>
    <section>
        <button mat-raised-button class="submit-button" color="primary" type="submit">Activate</button>
    </section>
</fieldset>
</form>