<mat-toolbar class="nav-toolbar">
    <div style="display:contents;">
        <button mat-icon-button mat-button color="primary" (click)="toggleDrawer()" *ngIf="loggedIn">
            <mat-icon>menu</mat-icon>
        </button>

        <img src="../assets/Bubbles.png" width="30px" height="30px">
        <button mat-button disableRipple class="nav-button home-button" [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']">UID Software Portal
            ({{version}})
        </button>
        <span class="spacer" *ngIf="loggedIn"></span>
        <button mat-button class="nav-button" style="color: var(--blue)" disableRipple *ngIf="loggedIn"
            [matMenuTriggerFor]="menu"><mat-icon>person</mat-icon> {{user.username}}
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item disableRipple [routerLinkActive]="['link-active']"
                [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/resetPassword']"
                [state]="{usernameToReset: null}" *ngIf="loggedIn">Reset Password
            </button>
            <button mat-menu-item (click)="logout()" disableRipple *ngIf="loggedIn">Logout
            </button>
        </mat-menu>
    </div>
</mat-toolbar>