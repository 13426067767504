import { Component, OnInit, Inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { AddClaimRequest } from '../../interfaces/add-claim-request';
import { OrderClaimRequest } from '../../interfaces/order-claim-request';
import { LicenseClaimRequest } from '../../interfaces/license-claim-request';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmOverwriteComponent } from '../confirm-overwrite/confirm-overwrite.component';

@Component({
  selector: 'app-add-additional-claim',
  templateUrl: './add-additional-claim.component.html',
  styleUrls: ['./add-additional-claim.component.css']
})
export class AddAdditionalClaimComponent implements OnInit {

  httpClient: HttpClient;
  apiUrl: string;

  addClaimForm: FormGroup;
  formSubmitting: boolean = false;
  encrypted: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddClaimRequest, private dialog: MatDialog, http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = `${ApiHelper.GetUrl(ApiPaths.License)}/${ApiPaths.AdditionalClaim}`;
  }

  ngOnInit(): void {
    this.addClaimForm = new FormGroup({
      key: new FormControl(''),
      value: new FormControl('')
    });
  }
  /*
   * Encrytion checkbox binding
   */
  onChange(event: MatCheckboxChange) {
    this.encrypted = event.checked;
  }
    /*
   * Opens the confirm overwrite dialog
   */
    confirmOverwrite() {
      let dialogRef = this.dialog.open(ConfirmOverwriteComponent, {
        data: {
          ClaimOrderId: this.data.ClaimOrderId,
          ClaimLicenseGuid: this.data.ClaimLicenseGuid,
          Key: this.addClaimForm.value.key,
          Value: this.addClaimForm.value.value,
          Encrypted: this.encrypted
        },
        autoFocus: false,
        panelClass: "view-panel"
      });
      dialogRef.afterClosed().subscribe().add(() => {
        this.dialog.closeAll();
      });
    }
  /*
   * Decides whether to add an additional claim to an order or license based on parent parameters
   */
  addAdditionalClaim() {
    var overwrite = false;
    if (this.data.claimKeys.length > 0) {
      this.data.claimKeys.forEach(key => {
        if (key == this.addClaimForm.value.key) {
          overwrite = true;
        }
      });
    }
    if (overwrite) {
      this.confirmOverwrite();
    } else if (this.data.ClaimOrderId != null && this.data.ClaimLicenseGuid == null) {
      this.addOrderClaim();
    } else if (this.data.ClaimOrderId == null && this.data.ClaimLicenseGuid != null) {
      this.addLicenseClaim();
    } else {
      SnackBarHelper.sendSnackbar(this.snackBar, "Adding an additional claim requires either a License Order ID or a License Guid", "failure-snackbar");
    }
  }
  /*
   * Adds an additional claim to a license order
   */
  addOrderClaim() {
    const request: OrderClaimRequest = {
      LicenseOrderId: this.data.ClaimOrderId,
      Key: this.addClaimForm.value.key,
      Value: this.addClaimForm.value.value,
      IsEncrypted: this.encrypted
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/addLicenseOrderClaim`, request).subscribe({
      next: (response) => {
        if (response.body) {
          SnackBarHelper.sendSnackbar(this.snackBar, `Additional claim successfully added`, "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        }
      }
    }).add(() => {
      this.formSubmitting = false;

    })
  }
  /*
   * Adds an additional claim to a license
   */
  addLicenseClaim() {
    const request: LicenseClaimRequest = {
      LicenseGuid: this.data.ClaimLicenseGuid,
      Key: this.addClaimForm.value.key,
      Value: this.addClaimForm.value.value,
      IsEncrypted: this.encrypted
    }
    this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/addLicenseClaim`, request).subscribe({
      next: (response) => {
        if (response.success) {
          SnackBarHelper.sendSnackbar(this.snackBar, `Additional claim successfully added`, "success-snackbar");
          this.dialogRef.close(true);
        } else {
          SnackBarHelper.sendSnackbar(this.snackBar, response.errorMessage, "failure-snackbar");
        }
      }
    }).add(() => {
      this.formSubmitting = false;
    })
  }
}
