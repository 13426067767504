import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { LoginService } from './login.service';
import { User } from 'src/app/users/interfaces/user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarHelper } from '../snackbar-helper';

@Injectable()
export class RequestIntercepter implements HttpInterceptor, OnDestroy {

  private user: User;

  constructor(private service: LoginService, private snackBar: MatSnackBar) {
    service.observeUser.subscribe({
      next: (new_user) => {
        this.user = new_user;
      }
    })
  }

  ngOnDestroy(): void {
    this.service.observeUser.unsubscribe();
  }

  /**
   * Intercepts outgoing HTTP requests and adds appropriate properties
   * @param request request that being sent
   * @param next http dispatcher
   * @returns http request response
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.service.tokenExists) {
      const token = this.service.getToken;
      const authReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
      // TODO: LIC-187 add user id in outgoing requests
      return next.handle(authReq).pipe(
        catchError((requestError) => {
          // Log the request error text to console
          console.log(`Request Error: ${requestError.statusText}`);

          // Prevents random logouts from stuff like refreshing before a page loads
          if (requestError.statusText != "Unknown Error") {
            this.service.logout();
            return throwError(() => new Error(requestError));
          }
        })
      );
    }
    return next.handle(request)
  }
}
