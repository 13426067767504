import { formatDate } from "@angular/common";
/**
 * Helper for time difference and formatting
 */
export class TimeHelpers {
    /**
     * Calculates difference in days between startDate and endDate
     * @param startDate start date
     * @param endDate end date
     * @returns difference in days
     */
    static daysUntil(startDate: Date, endDate: Date) {
        if (startDate == null || endDate == null) {
            return null;
        }
        try {
            var difference = endDate.getTime() - startDate.getTime();
            return Math.round(difference / (1000 * 60 * 60 * 24));
        }
        catch {
            return null;
        }
    }

    /**
    * Format date to YYYY-MM-DD HH:MM:SS A
    * @param date input date
    * @returns formatted status update string
    */
    static formatWithTime(date: string) {
        if (date == null) {
            return "-";
        }
        try {
            return formatDate(date, "yyyy-MM-dd hh:mm:ss a", "en")
        }
        catch {
            return "-";
        }
    }
    /**
    * Format date as YYYY-MM-DD
    * @param date ISO time string
    * @returns formatted date
    */
    static formatDate(date: string) {
        if (date == null) {
            return "-";
        }
        try {
            return formatDate(date, "yyyy-MM-dd", "en")
        }
        catch {
            return "-";
        }
    }
    /**
     * add days to provided date
     * @param date starting date
     * @param days number of days to add
     * @returns new date
     */
    static addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}