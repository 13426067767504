import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiPaths } from 'src/app/enums/api-paths';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivationRequest } from '../interfaces/activation-request';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { DownloadService } from 'src/app/download-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiVersions } from 'src/app/enums/api-versions';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-offline-activation',
  templateUrl: './offline-activation.component.html',
  styleUrls: ['./offline-activation.component.css']
})
/** Component used to activate a license offline
 *  The process is 2 steps
 *  1) The customer send us an activation code that one of the apps (PiSuite, Mouse Matrix, etc) generated
 *  2) We input the activation code, click activate, and we get a license file downloaded
 */
export class OfflineActivationComponent implements OnInit {

  private httpClient: HttpClient;
  private downloadUrl: string;

  offlineActivationForm: FormGroup;
  initalOfflineActivationForm: FormGroup;

  formSubmitting: boolean;

  errorMessage: string;

  constructor(http: HttpClient, private snackBar: MatSnackBar) {
    this.httpClient = http;
    this.downloadUrl =ApiHelper.GetUrl(ApiPaths.License);
  }

  ngOnInit() {
    this.offlineActivationForm = new FormGroup({
      activationCode: new FormControl('', [Validators.required])
    });
    this.initalOfflineActivationForm = this.offlineActivationForm;
  }

  /** Submit the request to activate the license and download the license file */
  OnSubmit() {
    try {
      if (this.offlineActivationForm.valid) {
        this.formSubmitting = true;
        let activation_code: ActivationRequest = JSON.parse(this.offlineActivationForm.controls.activationCode.value);

        if (activation_code.Key == null || activation_code.SecretVersion == null || activation_code.EncryptedMachineInformation == null) {
          this.onActivationError("Failed to validate license");
          return;
        }

        this.activateLicense(activation_code).subscribe(result => {
          if (result.success) {
            this.downloadLicense(activation_code);
            this.onActivationSuccess();
          }
          else
            this.onActivationError(result.errorMessage);
        });
      }
    }
    catch {
      this.onActivationError("Unknown Failure has occurred.");
    }

  }
  /** Method called when an activation step fails
   *  @param error_msg - Message to show
  */
  onActivationError(error_msg: string) {
    SnackBarHelper.sendSnackbar(this.snackBar, error_msg, "failure-snackbar");
    this.formSubmitting = false;
  }
  /** Method called when a license is successfully activated */
  onActivationSuccess() {
    SnackBarHelper.sendSnackbar(this.snackBar, "License Successfully Activated!", "success-snackbar");
    this.offlineActivationForm.reset(this.initalOfflineActivationForm);
    this.formSubmitting = false;
  }

  /** Activates the license with the provided request 
   *  @param request - Activation Request from the customer
  */
  activateLicense(request: ActivationRequest) {
    let version = request.ApplicationGuid == null ? ApiVersions.V1 : ApiVersions.V2;
    let apiUrl = ApiHelper.GetUrl(ApiPaths.License, version);
    return this.httpClient.post<ApiResponse<null>>(`${apiUrl}/activate?mode=offline`, request)
  }
  /**
   * Downloads the license file for the activation request provided
   * @param request the activation request to download the license for
   */
  downloadLicense(request: ActivationRequest) {
    let download_request = `${this.downloadUrl}/download?key=${request.Key}&secret_version=${request.SecretVersion}`;
    let download_service = new DownloadService(this.httpClient);
    download_service.download(download_request);
  }
}
