import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiPaths } from 'src/app/enums/api-paths';
import { Team } from 'src/app/teams/interfaces/teams.interface';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Company } from '../Interfaces/company.interface';
import { LicenseOrder } from 'src/app/license-orders/interfaces/license-order.interface';
import { PaginatorObject } from 'src/app/common/Interfaces/paginator-object';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatedApiResponse } from 'src/app/common/Interfaces/paginated-api-repsonse.interface';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.css']
})
/**
 * Component used view company information
 */
export class ViewCompanyComponent implements OnInit {

  private httpClient: HttpClient;
  private teamsUrl: string;
  private companyUrl: string;
  private licenseOrdersUrl: string;
  public company = {
    id: 0,
    name: "",
  };

  public teamsLoaded = false;
  public hasTeams = true;
  public teams: Team[];
  public teamsDataSource: MatTableDataSource<Team> = new MatTableDataSource();
  public teamsColumns: string[] = ['name', 'description'];

  public licenseOrdersLoaded = false;
  public licenseOrders: LicenseOrder[];
  public licenseOrdersDataSource: MatTableDataSource<LicenseOrder> = new MatTableDataSource();
  public licenseOrderColumns: string[] = ['name', 'referenceNumber', 'quantity', 'expirationDate', 'modulePreset', 'info'];
  public licenseOrderPaginatorObject: PaginatorObject = new PaginatorObject();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(http: HttpClient, private route: ActivatedRoute) {
    this.httpClient = http;
    this.teamsUrl = ApiHelper.GetUrl(ApiPaths.Team);
    this.companyUrl = ApiHelper.GetUrl(ApiPaths.Company);
    this.licenseOrdersUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
    this.licenseOrderPaginatorObject.pageSize = environment.shortPage;
  }

  ngOnInit() {
    this.loadCompanyInformation();
    this.loadTeams();
    this.loadLicenseOrders();
  }

  /**
   * Retrieve all teams for the passed in company from database
   */
  loadTeams() {
    this.httpClient.get<ApiResponse<Team[]>>(`${this.teamsUrl}?company_id=${this.route.snapshot.params.id}`).subscribe(result => {
      this.teams = result.body;
      this.teamsDataSource.data = result.body;
      this.teamsLoaded = true;
      if (this.teams.length == 0)
        this.hasTeams = false;
    },
      error => console.error(error));
  }

  /**
   * Loads company information from the database
   */
  loadCompanyInformation() {
    this.httpClient.get<ApiResponse<Company>>(`${this.companyUrl}/${this.route.snapshot.params.id}`).subscribe(result => {
      this.company = result.body;
    },
      error => console.error(error));
  }

  /**
   * Update license order list based on new page index
   * @param event event sent on page change
   */
  pageChanged(event: PageEvent) {
    this.licenseOrderPaginatorObject.onPageEvent(event);
    this.loadLicenseOrders();
  }

  /** Loads new license order list from database */
  loadLicenseOrders() {
    this.licenseOrdersLoaded = false;
    // Company Id added to api call in LIC-119
    let paged_url = `${this.licenseOrdersUrl}?${this.licenseOrderPaginatorObject.pageQuery}&company_id=${this.route.snapshot.params.id}&depth=3`;

    this.httpClient.get<PaginatedApiResponse<LicenseOrder[]>>(paged_url).subscribe(result => {
      this.licenseOrders = result.body;
      this.licenseOrderPaginatorObject.onPaginatedResponse(result);
      this.licenseOrdersDataSource.data = result.body;
      this.licenseOrdersLoaded = true;
    },
      error => console.error(error));
  }

  /**
   * Formats expiration date
   * @param date raw DateTime value
   * @returns date in YYYY-MM-DD format
   */
  formatDate(date: string) {
    return date.substring(0, 10);
  }
}
