import { License } from "src/app/licenses/interfaces/license.interface";
import { TimeHelpers } from "./time-helper";
/**
 * Class for license helpers
 */
export class LicenseHelper{
    /**
     * Gets string and color representation of license status
     * @param license license to check
     * @param expired if license has expired
     * @returns Activated, Expired, Disabled, or Unused status with coressponding color for text
     */
    static getStatusString(license: License, expired: boolean): [string, string]
    {
        if (!license.enabled)
            return ["Disabled", "var(--red)"];
        if (expired)
            return ["Expired", "var(--red)"]
        if(license.activated)
            return ["Activated", "var(--green)"];
        return ["Available", "var(--blue)"];
    }

    /**
     * Gets icon and color representation of license status
     * @param license license to check
     * @returns check, close, or remove icon with coressponding color
     */
    static getStatusIcon(license: License): [string, string]
    {
        if (!license.enabled)
            return ["close", "var(--red)"];
        var expired = TimeHelpers.daysUntil(new Date(Date.now()), new Date(license.expirationDate)) < 0
        if (expired)
            return ["close", "var(--red)"]
        if (license.activated)
            return ["check", "var(--green)"]
        return ["remove", "var(--blue)"];
    }
    /**
     * Determines if the public key is valid
     * @param license The license to check
     * @returns True if the key is valid, false otherwise
     */
    static validPublicKey(license: License) : boolean
    {
        return license.publicKey != null;
    }
}