import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../common/Helpers/login/login.service';
import { Location } from '@angular/common';
import { shortcutRoutes } from '../app-routes';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, OnDestroy {
  /** Input of if the side menu is open or closed */
  @Input() toggle: boolean = false;

  private routes = shortcutRoutes;
  loggedIn: boolean = false;
  public selectedButton: string = "";

  constructor(private service: LoginService, private location: Location, private router: Router) {
  }

  ngOnInit(): void {
    this.selectedButton = `.${this.location.path() ? this.location.path() : "/"}`;
    this.service.observeAuth.subscribe(value => {
      this.loggedIn = value == false ? this.service.tokenExists : value;
    });
  }

  ngOnDestroy(): void {
    this.service.observeAuth.unsubscribe();
  }

  /**
   * Navigate to new Url and highlight correct navigation button
   * @param button url to naviate to
   */
  setClicked(button: string) {
    this.router.navigate([button])
    this.selectedButton = button
  }
}
