import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { EditLicenseOrderData } from '../../interfaces/edit-license-order-data';
import { LicenseOrderDisableRequest } from '../../interfaces/license-order-disable-request';

@Component({
  selector: 'app-disable-license-order',
  templateUrl: './disable-license-order.component.html',
  styleUrls: ['./disable-license-order.component.css']
})
export class DisableLicenseOrderComponent implements OnInit {

  httpClient: HttpClient;
  apiUrl: string;
  public formSubmitting: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditLicenseOrderData,  http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder)
    this.formSubmitting = false;
   }

  ngOnInit(): void {
  }

  /**
   * Disables current license order and closes on success
   */
  disableLicense() {
      const request: LicenseOrderDisableRequest = {
        orderId: this.data.licenseOrderId,
        disabledById: 1
      }
      this.formSubmitting = true;

      this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/disable`, request).subscribe({
        next: (response) => {
          if (response.body) {
            SnackBarHelper.sendSnackbar(this.snackBar, "License Order Successfully Disabled", "success-snackbar");
            this.dialogRef.close(true);
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, "Disable License Order Failed", "failure-snackbar");
          }
        }
      }).add(() => {
        this.formSubmitting = false;
      })
    }
  }