/**
 * Helpers for using and formatting strings
 */
export class StringHelper {
    /**
     * Determines if a string is null or empty
     * @param str string to check
     * @returns true if input is null or empty
     */
    static isNullOrEmpty(str: string): boolean {
        return str == null || str.length == 0;
    }
}