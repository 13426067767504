import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaths } from 'src/app/enums/api-paths';
import { Team } from 'src/app/teams/interfaces/teams.interface';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { User } from '../interfaces/user.interface';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
/**
 * Component used view company information
 */
export class ViewUserComponent implements OnInit {

  private httpClient: HttpClient;
  private userUrl: string;
  private teamUrl: string;

  public user: User = {
    id: 0,
    username: "",
    displayName: "",
    emailAddress: "",
  }

  public teamsLoaded = false;
  public hasTeams = true;
  public teams: Team[];
  public teamsDataSource: MatTableDataSource<Team> = new MatTableDataSource();
  public teamsColumns: string[] = ['name', 'company'];

  constructor(http: HttpClient, private route: ActivatedRoute) {
    this.httpClient = http;
    this.userUrl = ApiHelper.GetUrl(ApiPaths.User);
    this.teamUrl = ApiHelper.GetUrl(ApiPaths.Team);
  }

  ngOnInit() {
    this.loadUserInformation();
    this.loadTeams();
  }

  /**
   * Loads user information from the database
   */
  loadUserInformation() {
    this.httpClient.get<ApiResponse<User>>(`${this.userUrl}/${this.route.snapshot.params.id}?depth=2`).subscribe(result => {
      this.user = result.body;
    },
      error => console.error(error));
  }

  /**
   * Retrieve all teams for the passed in user from database
   */
   loadTeams() {
    this.httpClient.get<ApiResponse<Team[]>>(`${this.teamUrl}?user_id=${this.route.snapshot.params.id}&depth=1`).subscribe(result => {
      this.teams = result.body;
      this.teamsDataSource.data = result.body;
      this.teamsLoaded = true;
      if (this.teams.length == 0)
        this.hasTeams = false;
    },
      error => {
        console.error(error)
        this.teamsLoaded = true;
        this.hasTeams = false;
      });
  }
}
