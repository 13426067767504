import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiResponse } from "src/app/common/Interfaces/api-response.interface";
import { CompanySelectionComponent } from "src/app/companies/company-selector/company-selector.component";
import { Company } from "src/app/companies/Interfaces/company.interface";
import { ApiPaths } from "src/app/enums/api-paths";
import { Team } from "src/app/teams/interfaces/teams.interface";
import { MatDialog } from "@angular/material/dialog"
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamCreateRequest } from "../interfaces/createTeamRequest.interface";
import { CreateResponse } from "src/app/common/Interfaces/create-response.interface";
import { FormHelpers } from "src/app/common/Helpers/form-helper";
import { SnackBarHelper } from "src/app/common/Helpers/snackbar-helper";
import { ApiHelper } from "src/app/common/Helpers/api-helper";

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})

export class CreateTeamComponent implements OnInit {
  private httpClient: HttpClient;
  private teamUrl: string;

  submitted: boolean;
  success: boolean;
  failed: boolean;
  formSubmitting: boolean = false;

  createTeamForm: FormGroup;

  teams: Team[] = [];

  errorMessage: string;
  public selectedCompany: Company = {
    name: "",
    id: 0,
  }

  constructor(http: HttpClient, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.httpClient = http;
    this.teamUrl = ApiHelper.GetUrl(ApiPaths.Team)
  }

  ngOnInit(): void {
    this.createTeamForm = new FormGroup({
      teamName: new FormControl('', Validators.required),
      teamDescription: new FormControl(''),
      companyField: new FormControl(''),
    });
  }

  /**
   * Opens company selection dialog and handles company selection
   */
  selectCompany(event: Event) {
    event.preventDefault();
    this.createTeamForm.controls.companyField.disable({
      onlySelf: true
    })

    let dialogRef = this.dialog.open(CompanySelectionComponent, {
      autoFocus: false,
      panelClass: "company-panel"
    })

    dialogRef.afterClosed().subscribe(company => {
      this.createTeamForm.controls.companyField.enable({
        onlySelf: true
      })
      if (company != null) {
        this.selectedCompany = company;
        this.createTeamForm.controls.companyField.setErrors(null);
      }
    },
      error => console.log(error));
  }

  /**
   * Submits form if valid and sends success/fail feedback
   */
  OnSubmit() {
    if (this.createTeamForm.valid && this.selectedCompany.id != 0) {
      const request: TeamCreateRequest = {
        name: this.createTeamForm.value.teamName,
        description: this.createTeamForm.value.teamDescription ?? '',
        companyId: this.selectedCompany.id,
      };
      this.formSubmitting = true;
      this.httpClient.post<ApiResponse<CreateResponse>>(this.teamUrl, request).subscribe(result => {
        if (result.success) {
          SnackBarHelper.sendSnackbar(this.snackBar, `Team Created!`, 'success-snackbar');
          this.formSubmitting = false;
          FormHelpers.resetForm(this.createTeamForm);
        }
        else {
          this.formSubmitting = false;
          SnackBarHelper.sendSnackbar(this.snackBar, `Error: ${result.errorMessage}`, 'failure-snackbar')
        }
      },
        error => {
          this.formSubmitting = false;
          SnackBarHelper.sendSnackbar(this.snackBar, `Error: Unknown Error`, 'failure-snackbar')
        });
    }
  }
}