import { ApiPaths } from "src/app/enums/api-paths";
import { ApiVersions } from "src/app/enums/api-versions";
import { environment } from "src/environments/environment";
/**
 * Helper for api url building
 */
export class ApiHelper {

    /**
     * Builds complete api url for given endpoint and version
     * @param endpoint ApiPath endpoint
     * @param version Version to use
     * @returns complete url to use
     */
    static GetUrl(endpoint: ApiPaths, version: ApiVersions = ApiVersions.DEFAULT_VERSION) : string
    {
        if (endpoint == ApiPaths.Login)
            return `${environment.baseUrl}/${ApiPaths.Login}`
        return `${environment.baseUrl}/${version}/${endpoint}`
    }
}
