<a mat-dialog-title>Companies</a>

<mat-dialog-content>
    <app-uid-spinner [visible]="!loaded"></app-uid-spinner>
    <div *ngIf="loaded">
        <mat-table [dataSource]="companiesDataSource">
            <ng-container matColumnDef="key">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns;" (click)="selectCompany(row)"
                [ngClass]="{'selected-row': selectedCompany.id == row.id}">{{row.name}}
            </mat-row>
        </mat-table>

        <mat-paginator [pageIndex]="paginatorObject.pageIndex" [pageSize]="paginatorObject.pageSize"
            [length]="paginatorObject.totalRecords" (page)="pageChanged($event)" aria-label="Select page" hidePageSize>
        </mat-paginator>

    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" class="dialog-button" [disabled]="selectedCompany.name == null" [mat-dialog-close]="selectedCompany">Select</button>
    <button mat-stroked-button class="dialog-button" (click)="OnCancel()">Cancel</button>
  </mat-dialog-actions>
