import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CompaniesComponent } from './companies/companies.component';
import { AddCompanyComponent } from './companies/add-company/add-company.component';
import { LicensesComponent } from './licenses/licenses.component';
import { OfflineActivationComponent } from './licenses/offline-activation/offline-activation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CreateLicenseOrderComponent } from './license-orders/create-license-order/create-license-order.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CompanySelectionComponent } from './companies/company-selector/company-selector.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ViewCompanyComponent } from './companies/view-company/view-company.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ViewLicenseOrderComponent } from './license-orders/view-license-order/view-license-order.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UsersComponent } from './users/users.component';
import { LicenseOrdersComponent } from './license-orders/license-orders.component';
import { CreateTeamComponent } from './teams/create-team/create-team.component';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { ViewUserComponent } from './users/view-user/view-user.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './common/Helpers/login/auth.guard';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddUserComponent } from './users/add-user/add-user.component';
import { ViewLicenseComponent } from './licenses/view-license/view-license.component';
import { LoginService } from './common/Helpers/login/login.service';
import { RequestIntercepter } from './common/Helpers/login/request-intercepter.interceptor';
import {MatMenuModule} from '@angular/material/menu'; 
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ValidatePasswordDirective } from './login/directives/validate-password.directive';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { UIDSpinnerComponent } from './common/spinners/uid-spinner/uid-spinner.component';
import { ModalSpinnerComponent } from './common/spinners/modal-spinner/modal-spinner.component';
import {MatSidenavModule} from '@angular/material/sidenav'; 
import { SideNavComponent } from './side-nav/side-nav.component';
import { appRouting } from './app-routes';
import { ExtendExpirationComponent } from './license-orders/edit-license-order-dialogs/extend-expiration/extend-expiration.component';
import { DisableLicenseOrderComponent } from './license-orders/edit-license-order-dialogs/disable-license-order/disable-license-order.component';
import { AddLicensesComponent } from './license-orders/edit-license-order-dialogs/add-licenses/add-licenses.component';
import { CalendarShortCutsComponent } from './common/components/calendar-short-cuts/calendar-short-cuts.component';
import {MatChipsModule} from '@angular/material/chips'; 
import { NavigationService } from './common/Helpers/services/navigation.service';
import { SetUpdateTrackDialogComponent } from './tracks/set-update-track-dialog/set-update-track-dialog.component';
import { EditModulesComponent } from './license-orders/edit-license-order-dialogs/edit-modules/edit-modules.component';
import { AdditionalClaimComponent } from './additional-claim/additional-claim.component';
import { AddAdditionalClaimComponent } from './additional-claim/dialogs/add-additional-claim/add-additional-claim.component';
import { DeleteAdditionalClaimComponent } from './additional-claim/dialogs/delete-additional-claim/delete-additional-claim.component';
import { ActiveVersionDetailsComponent } from './home/active-version-details/active-version-details.component';
import { ConfirmOverwriteComponent } from './additional-claim/dialogs/confirm-overwrite/confirm-overwrite.component';
import { TransferLicenseComponent } from './licenses/transfer-license/transfer-license.component';
import { HistoryItemsComponent } from './history-items/history-items.component';
import { ViewHistoryItemComponent } from './history-items/dialogs/view-history-item/view-history-item.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CompaniesComponent,
    AddCompanyComponent,
    LicensesComponent,
    OfflineActivationComponent,
    ViewCompanyComponent,
    ViewLicenseOrderComponent,
    CreateLicenseOrderComponent,
    CompanySelectionComponent,
    ViewCompanyComponent,
    UsersComponent,
    LicenseOrdersComponent,
    ViewUserComponent,
    CreateTeamComponent,
    LoginComponent,
    AddUserComponent,
    ViewLicenseComponent,
    ResetPasswordComponent,
    ValidatePasswordDirective,
    EditUserComponent,
    UIDSpinnerComponent,
    ModalSpinnerComponent,
    SideNavComponent,
    ExtendExpirationComponent,
    DisableLicenseOrderComponent,
    AddLicensesComponent,
    CalendarShortCutsComponent,
    SetUpdateTrackDialogComponent,
    EditModulesComponent,
    AdditionalClaimComponent,
    AddAdditionalClaimComponent,
    DeleteAdditionalClaimComponent,
    ActiveVersionDetailsComponent,
    ConfirmOverwriteComponent,
    ActiveVersionDetailsComponent,
    TransferLicenseComponent,
    HistoryItemsComponent,
    ViewHistoryItemComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    appRouting,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    ClipboardModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSidenavModule,
    MatChipsModule
  ],
  providers: [
    LoginService, 
    NavigationService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestIntercepter, multi: true }  
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
