<mat-toolbar class="section-header view-section-header">
    <a class="section-header-title">License Order - {{licenseOrder.name}}</a>
    <span class="spacer"></span>
    <button mat-raised-button color="warn" (click)="disableLicense($event)">Deactivate All Licenses</button>
</mat-toolbar>
<mat-toolbar-row class="subtitle-row">
    <a class="subtitle"> {{team.company.name}} - {{team.name}}</a>
</mat-toolbar-row>
<mat-divider></mat-divider>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Order Details</a>
        <span class="spacer"></span>
    </div>
    <div class="flex">
        <mat-card-content class="stats-card-content">
            <a class="bolded">Reference Number</a>
            <a class="bordered">{{licenseOrder.referenceNumber}}</a>
        </mat-card-content>
        <mat-card-content class="stats-card-content">
            <a class="bolded">Expiration Date</a>
            <div class="flex">
                <a class="bordered">{{ExpirationDateString(licenseOrder.expirationDate)}}</a>
                <button mat-icon-button color="primary" class="inline-icon-button" (click)="extendLicense($event)"
                    disableRipple>
                    <mat-icon svgIcon="uid_edit"></mat-icon>
                </button>
            </div>
            <div *ngIf="daysUntilExpiration != null">
                <a *ngIf="daysUntilExpiration > 0">Expires in {{daysUntilExpiration}} days</a>
                <a class="warning-text" *ngIf="daysUntilExpiration <= 0">Expired</a>
            </div>
        </mat-card-content>
    </div>
    <div class="flex half-width">
        <mat-card-content class="stats-card-content">
            <a class="bolded">Licenses</a>
            <a class="bordered"> {{activated}}/{{licenseOrder.licenses.length}} Activated </a>
        </mat-card-content>
    </div>
</mat-card>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Licenses</a>
        <button mat-icon-button class="add-icon-button" (click)="addLicenses($event)" disableRipple>
            <mat-icon svgIcon="uid_add"></mat-icon>
        </button>
        <span class="spacer"></span>
        <button mat-icon-button matTooltip="Export Keys" (click)="exportKeys()" disableRipple>
            <mat-icon svgIcon="uid_export"></mat-icon>
        </button>
    </div>

    <app-uid-spinner [visible]="!licensesLoaded"></app-uid-spinner>
    <div *ngIf="licensesLoaded">
        <mat-table [dataSource]="licensesDataSource">

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef style="flex: .2;"></mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: .2;">
                    <mat-icon [ngStyle]="{'color': getStatusIconColor(row)}">{{getStatusIcon(row)}}</mat-icon>
                    <img *ngIf="!validPublicKey(row)" src="../../../assets/caution_uid_yellow.png" width="20px" height="20px" matTooltip="Missing Public Key" matTooltipPosition="right">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="key">
                <mat-header-cell *matHeaderCellDef style="flex: 5%;"> Key </mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: 5%;">
                    {{row.guid}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="activated">
                <mat-header-cell *matHeaderCellDef> Activated On </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ActivationDateString(row.activatedOn)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="mode">
                <mat-header-cell *matHeaderCellDef> Activation Mode </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.mode ? row.mode : "-"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="checkIn">
                <mat-header-cell *matHeaderCellDef> Last Check In </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{LastCheckInString(row.lastStatusUpdate)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="export">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button mat-icon-button color="primary" [routerLink]="['/licenses/',row.guid]" disableRipple>
                        <mat-icon>search</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" matTooltip="Copy License Key" matTooltipPosition="right"
                        [cdkCopyToClipboard]="row.guid">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Transfer License" [routerLink]="['/licenses/transfer/',row.guid]" matTooltipPosition="right">
                        <img src="../../../assets/folder_uid_blue.png" width="25px" height="25px" />
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="licenseColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: licenseColumns;">
            </mat-row>
        </mat-table>

        <mat-paginator color="primary" [pageIndex]="licensePaginatorObject.pageIndex"
            [pageSize]="licensePaginatorObject.pageSize" [length]="licensePaginatorObject.totalRecords"
            (page)="licensePageChanged($event)" aria-label="Select page" hidePageSize>
        </mat-paginator>
    </div>
</mat-card>

<app-additional-claim [orderId]="licenseOrder.id" [licenses]="licenseOrder.licenses"></app-additional-claim>

<mat-card class="view-information-card">
    <div class="view-information-toolbar">
        <a class="view-information-table-title">Modules</a>
        <button mat-icon-button class="add-icon-button" (click)="editModules($event)" disableRipple>
            <mat-icon svgIcon="uid_edit"></mat-icon>
        </button>
    </div>
    <app-uid-spinner [visible]="!modulesLoaded"></app-uid-spinner>
    <div *ngIf="modulesLoaded">
        <mat-table [dataSource]="modulesDataSource">
            <ng-container matColumnDef="application">
                <mat-header-cell *matHeaderCellDef style="flex: 5%;"> Application </mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: 5%;"> {{row.applicationName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Module </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="moduleColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: moduleColumns;">
            </mat-row>
        </mat-table>

        <mat-paginator color="primary" [pageIndex]="modulePaginatorObject.pageIndex"
            [pageSize]="modulePaginatorObject.pageSize" [length]="modulePaginatorObject.totalRecords"
            (page)="modulePageChanged($event)" aria-label="Select page" hidePageSize>
        </mat-paginator>
    </div>
</mat-card>

<app-history-items [historySetId]="licenseOrder.historySetId" [reload]="reloadHistoryFlag"></app-history-items>