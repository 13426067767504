import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiPaths } from 'src/app/enums/api-paths';
import { UserCreateRequest } from '../interfaces/userCreateRequest.interface';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateResponse } from 'src/app/common/Interfaces/create-response.interface';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
/**
 * Component used to add a new user to the system
 */
export class AddUserComponent implements OnInit {

  private httpClient: HttpClient;
  private apiUrl: string;

  addUserForm: FormGroup;
  formSubmitting: boolean = false;

  constructor(http: HttpClient, private snackBar: MatSnackBar) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.User);
  }

  ngOnInit() {
    this.addUserForm = new FormGroup({
      username: new FormControl('', Validators.pattern(/^[a-zA-Z\d.@]+$/)),
      name: new FormControl(''),
      displayName: new FormControl(''),
      email: new FormControl('', Validators.email),
      password: new FormControl('', Validators.minLength(8))
    });
  }

  /**
   * Validates and attemps to add the user to the database
   */
  OnSubmit() {
    if (this.addUserForm.valid) {

      // TODO: LIC-156 Validatie username here 
      this.formSubmitting = true;

      const user_to_add: UserCreateRequest = {
        name: this.addUserForm.value.name,
        username: this.addUserForm.value.username,
        displayName: this.addUserForm.value.displayName,
        emailAddress: this.addUserForm.value.email,
        password: this.addUserForm.value.password,
        createdById: 1
      };

      this.httpClient.post<ApiResponse<CreateResponse>>(this.apiUrl, user_to_add).subscribe(result => {
        if (result.success) {
          SnackBarHelper.sendSnackbar(this.snackBar, "User Created", "success-snackbar")
          this.formSubmitting = false;
          FormHelpers.resetForm(this.addUserForm)
        }
        else {
          this.formSubmitting = false;
          SnackBarHelper.sendSnackbar(this.snackBar, "User Creation Failed", "failure-snackbar")
        }
      }, error => {
        this.formSubmitting = false
        SnackBarHelper.sendSnackbar(this.snackBar, "User Creation Failed", "failure-snackbar")
      });
    }
  }
}
