import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiResponse } from "src/app/common/Interfaces/api-response.interface";
import { CompanySelectionComponent } from "src/app/companies/company-selector/company-selector.component";
import { Company } from "src/app/companies/Interfaces/company.interface";
import { ApiPaths } from "src/app/enums/api-paths";
import { Team } from "src/app/teams/interfaces/teams.interface";
import { MatDialog } from "@angular/material/dialog"
import { LicenseOrderCreateRequest } from "../interfaces/license-order-create-request.interface";
import { LicenseOrderCreateResponse } from "../interfaces/license-order-create-response.interface";
import { FormHelpers } from "src/app/common/Helpers/form-helper";
import { SnackBarHelper } from "src/app/common/Helpers/snackbar-helper";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModulePreset } from "src/app/module-presets/interfaces/module-preset.interface";
import { Application } from "src/app/applications/interfaces/application.interface";
import { PaginatorObject } from "src/app/common/Interfaces/paginator-object";
import { ApiHelper } from "src/app/common/Helpers/api-helper";
import { Router } from "@angular/router";

@Component({
  selector: 'app-create-license-order',
  templateUrl: './create-license-order.component.html',
  styleUrls: ['./create-license-order.component.css']
})

export class CreateLicenseOrderComponent implements OnInit {
  //#region properties
  private httpClient: HttpClient;
  private teamUrl: string;
  private licenseOrderUrl: string;
  private modulePresetUrl: string;
  private applicationUrl: string;

  orderLicenseForm: FormGroup;

  teams: Team[] = [];
  modulePresets: ModulePreset[] = [];
  applications: Application[] = [];
  selectedModules: string[] = [];

  public appsLoaded: boolean = false;
  public formSubmitting: boolean = false;

  public applicationModuleCount = {}

  public step: string = "";

  errorMessage: string;
  public selectedCompany: Company = {
    name: "",
    id: 0,
  }
  
  minDate: Date = new Date();
  //#endregion

  //#region constructor
  constructor(http: HttpClient, public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router) {
    this.httpClient = http;
    this.licenseOrderUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder);
    this.teamUrl = ApiHelper.GetUrl(ApiPaths.Team);
    this.modulePresetUrl = ApiHelper.GetUrl(ApiPaths.ModulePreset);
    this.applicationUrl = ApiHelper.GetUrl(ApiPaths.Application);

    this.minDate = new Date(Date.now());
  }
  //#endregion

  //#region OnInit
  ngOnInit(): void {
    this.orderLicenseForm = new FormGroup({
      orderName: new FormControl('', Validators.required),
      orderDescription: new FormControl(''),
      referenceNumber: new FormControl('', Validators.required),
      companyField: new FormControl(''),
      teamName: new FormControl('', Validators.required),
      quantity: new FormControl('', [Validators.min(1), Validators.max(500), Validators.required]),
      expirationDate: new FormControl('', Validators.required),
      modulePreset: new FormControl('Custom')
    });
    this.loadModulePresets();
    this.loadApplications();
  }
  //#endregion

  //#region formatters
  /**
     * Checks if checkbox is selected
     * @param guid guid of checkbox
     * @returns true if selected, false if not
     */
  isSelected(guid: string) {
    return this.selectedModules.indexOf(guid) != -1;
  }
  //#endregion

  //#region events
  /**
   * Populates teams list based on selected company
   * @param id company's team to load
   */
  onCompanySelect(id: number) {
    this.httpClient.get<ApiResponse<Team[]>>(`${this.teamUrl}?company_id=${id}`).subscribe(result => {
      this.teams = result.body;
      if (this.teams.length == 0)
        this.orderLicenseForm.controls.companyField.setErrors({ "teams": "Company has no teams" })
    },
      error => console.error(error));
  }

  /**
 * Set expiration date from shortcut
 * @param event new expiration date
 */
  selectDate(event) {
      this.orderLicenseForm.controls.expirationDate.setValue(event);
    }
  /**
   * Adds or removed module from selected list
   * @param event clicked checkbox
   */
  selectModule(event) {
    this.orderLicenseForm.controls.modulePreset.setValue("Custom")
    if (event.checked) {
      this.selectedModules.push(event.source.value)
      this.applicationModuleCount[this.applications[this.applications.findIndex(x => x.modules.findIndex(y => y.guid == event.source.value) != -1)].guid] += 1;
    }
    else {
      this.selectedModules.splice(this.selectedModules.indexOf(event.source.value), 1);
      this.applicationModuleCount[this.applications[this.applications.findIndex(x => x.modules.findIndex(y => y.guid == event.source.value) != -1)].guid] -= 1;
    }
  }
  /**
   * set expanded panel
   * @param guid guid of selected panel
   */
  setStep(guid: string) {
    this.step = guid;
  }

  /**
   * Opens company selection dialog
   */
  selectCompany(event: Event) {
    event.preventDefault();
    this.orderLicenseForm.controls.companyField.disable({
      onlySelf: true
    })

    let dialogRef = this.dialog.open(CompanySelectionComponent, {
      autoFocus: false,
      panelClass: "company-panel"
    })
    dialogRef.afterClosed().subscribe(company => {
      this.orderLicenseForm.controls.companyField.enable({
        onlySelf: true
      })
      if (company != null) {
        this.selectedCompany = company;
        this.orderLicenseForm.controls.companyField.setErrors(null);
        this.onCompanySelect(company.id)
      }
    },
      error => console.log(error));
  }

  /**
   * If selected preset is real, set modules list to its modules
   * @param event selected module preset
   */
  setPreset(event) {
    if (event.value != null && event.value != "Custom") {
      let moduleGuids = this.modulePresets[this.modulePresets.findIndex(preset => preset.id == event.value)].moduleGuids
      this.selectedModules = moduleGuids.map(module => { return module });

      this.applications.forEach(app => {
        this.applicationModuleCount[app.guid] = 0;
      })
      moduleGuids.forEach(guid => {
        this.applicationModuleCount[this.applications[this.applications.findIndex(x => x.modules.findIndex(y => y.guid == guid) != -1)].guid] += 1
      })
    }
    else {
      this.selectedModules = [];
      this.applications.forEach(app => {
        this.applicationModuleCount[app.guid] = 0;
      })
    }

  }
  //#endregion

  //#region helpers
  /**
   * Get full list of module presets from database
   */
  loadModulePresets() {
    this.httpClient.get<ApiResponse<ModulePreset[]>>(`${this.modulePresetUrl}?page_size=${PaginatorObject.unpaginated}&depth=0`).subscribe(result => {
      this.modulePresets = result.body;
    },
      error => console.error(error));
  }
  /**
   * get full list of applications from database
   */
  loadApplications() {
    this.httpClient.get<ApiResponse<Application[]>>(`${this.applicationUrl}`).subscribe(result => {
      this.applications = result.body;
      this.appsLoaded = true;
      this.applications.forEach(app => {
        this.applicationModuleCount[app.guid] = 0;
      })
    },
      error => console.error(error));
  }

  /**
   * Submits form if valid and sends success/fail feedback
   */
  OnSubmit() {
    if (this.selectedModules.length < 1) {
      SnackBarHelper.sendSnackbar(this.snackBar, "Modules are required for license order", "failure-snackbar");
    }
    else if (this.orderLicenseForm.valid) {
      this.formSubmitting = true;
      const request: LicenseOrderCreateRequest = {
        referenceNumber: this.orderLicenseForm.value.referenceNumber,
        name: this.orderLicenseForm.value.orderName,
        teamId: this.orderLicenseForm.value.teamName,
        quantity: this.orderLicenseForm.value.quantity,
        expirationDate: this.orderLicenseForm.value.expirationDate ? this.orderLicenseForm.value.expirationDate?.toISOString() : null,
        description: this.orderLicenseForm.value.orderDescription ?? '',
        companyId: this.selectedCompany.id,
        moduleGuids: this.selectedModules,
        modulePresetId: this.orderLicenseForm.value.modulePreset != "Custom" ? this.orderLicenseForm.value.modulePreset : null,
      };

      this.httpClient.post<ApiResponse<LicenseOrderCreateResponse>>(this.licenseOrderUrl, request).subscribe({
        next: (result) => {
          if (result.success) {
            SnackBarHelper.sendSnackbar(this.snackBar, `License Order ${result.body.id} Created!`, 'success-snackbar');
            this.formSubmitting = false;
            FormHelpers.resetForm(this.orderLicenseForm)
            this.setPreset({ value: "Custom" })
            this.orderLicenseForm.controls.modulePreset.setValue("Custom")
            this.router.navigate(['/licenseOrders', result.body.id]);
          }
          else {
            this.formSubmitting = false;
            SnackBarHelper.sendSnackbar(this.snackBar, 'License Order Error', 'failure-snackbar')
          }
        },
        error: (e) => {
          this.formSubmitting = false;
          SnackBarHelper.sendSnackbar(this.snackBar, 'License Order Error', 'failure-snackbar')
        }
      });
    }
  }
  //#endregion
}