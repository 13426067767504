import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export const validatePasswordMatch : ValidatorFn = (control: AbstractControl): 
    ValidationErrors | null => {
      const password = control.get("newPassword");
      const repeatPassword = control.get("repeatPassword");

      return password && repeatPassword && password.value === repeatPassword.value ? null : {match: true};
    }

@Directive({
  selector: '[appValidatePassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidatePasswordDirective, multi: true }]
})

export class ValidatePasswordDirective implements Validator {
  //#region Validator
  /**
   * validates if password fields match
   * @param control FormControl containing password fields
   * @returns match validation error if passwords mismatch, null if match
   */
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return validatePasswordMatch(control);
  }
  //#endregion
}
