import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiHelper } from 'src/app/common/Helpers/api-helper';
import { FormHelpers } from 'src/app/common/Helpers/form-helper';
import { SnackBarHelper } from 'src/app/common/Helpers/snackbar-helper';
import { ApiResponse } from 'src/app/common/Interfaces/api-response.interface';
import { ApiPaths } from 'src/app/enums/api-paths';
import { ExtendLicenseOrder } from '../../interfaces/extend-license-order';
import { LicenseOrderExtendRequest } from '../../interfaces/license-order-extend-request';

@Component({
  selector: 'app-extend-expiration',
  templateUrl: './extend-expiration.component.html',
  styleUrls: ['./extend-expiration.component.css']
})
export class ExtendExpirationComponent implements OnInit {
  httpClient: HttpClient;
  apiUrl: string;

  extendLicenseForm: FormGroup;
  formSubmitting: boolean = false;

  minDate: Date = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExtendLicenseOrder,  http: HttpClient, private snackBar: MatSnackBar, public dialogRef: DialogRef) {
    this.httpClient = http;
    this.apiUrl = ApiHelper.GetUrl(ApiPaths.LicenseOrder)
    
    this.minDate.setFullYear(data.currentExpirationDate.getFullYear());
    this.minDate.setDate(data.currentExpirationDate.getDate() + 1);
    this.minDate.setMonth(data.currentExpirationDate.getMonth())
   }

  ngOnInit(): void {
    this.extendLicenseForm = new FormGroup({
      expirationDate: new FormControl('', Validators.required),
    });
  }

  /**
   * Extends license order and closes on success
   */
  extendLicense() {
    if (this.extendLicenseForm.valid) {
      this.formSubmitting = true;
      
      const request: LicenseOrderExtendRequest = {
        orderId: this.data.licenseOrderId,
        extendedExpirationDate: this.extendLicenseForm.value.expirationDate,
      }

      this.httpClient.post<ApiResponse<boolean>>(`${this.apiUrl}/extend`, request).subscribe({
        next: (response) => {
          if (response.body) {
            FormHelpers.resetForm(this.extendLicenseForm);
            SnackBarHelper.sendSnackbar(this.snackBar, "License Order Successfully Extended", "success-snackbar");
            this.dialogRef.close(true);
          } else {
            SnackBarHelper.sendSnackbar(this.snackBar, "Extend License Order Expiration Failed", "failure-snackbar");
          }
        }
      }).add(() => {
        this.formSubmitting = false;
      })
    }
  }
}
