import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { take } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {

  constructor(private loginService: LoginService, private router: Router) {}

  ngOnDestroy(): void {
    this.loginService.observeAuth.unsubscribe();
  }

  canActivate(): boolean | UrlTree {
    let route;
    this.loginService.observeAuth.pipe(take(1)).subscribe(
        {next: ((s: boolean) => route = (s ? true: this.router.parseUrl('/login')))}
      );
      this.loginService.verifyToken();
      return route;
  }
}
